// refer links 
// https://www.freecodecamp.org/news/how-to-use-redux-persist-when-migrating-your-states-a5dee16b5ead
// https://github.com/rt2zz/redux-persist/blob/master/docs/migrations.md

import { AnyAction, applyMiddleware, combineReducers, createStore } from "redux"
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { persistStore, persistReducer, createMigrate } from "redux-persist"
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import todosReducer, { RootState } from "./reducers/root"
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2"
import { migrations } from "./reduxMigration"
import calendarSlice, { initialStateCalendar } from "./reducers/calendarSlice";
import { initialState } from "./reducers/initialState";

const persistConfig = {
  key: "root",
  version: 1, // Starting with version 1
  storage,
  stateReconciler: autoMergeLevel2,
  migrate: createMigrate(migrations, { debug: true }),
};

// Correctly create a root reducer that handles logout
const rootReducer = (state: RootState, action: AnyAction) => {
  // Reset the entire state if LOGOUT action is dispatched
  if (action.type === 'LOGOUT_USER') {
    // This will reset both reducers to their initial states
    state = {
      opening: initialState,
      calendar: initialStateCalendar
        } ;
  }

  // Combine reducers after potential reset
  return combineReducers({
    opening: todosReducer,
    calendar: calendarSlice
  })(state, action);
};

const logger = () => {
  return (next: any) => {
    return (action: any) => {
      const result = next(action);
      return result;
    };
  };
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Export persistor and store globally
export let persistor: any;
export let store: any;

export default function configureAppStore(preloadState?: any) {
  const middlewares = [thunk];
  const composeEnhancers =
    process.env.REACT_APP_ENV === "production"
      ? applyMiddleware(...middlewares)
      : composeWithDevTools(applyMiddleware(...middlewares, logger));
  const store = createStore(persistedReducer, preloadState, composeEnhancers);
  let persistor = persistStore(store);
  return {
    store: store,
    persistor,
  };
}

// Updated logout action with proper import
export const resetApp = () => {
  return async (dispatch: any) => {
    // Dispatch logout action to reset reducers
    dispatch({ type: 'LOGOUT_USER' });

    // Clear persisted storage
    await persistor.purge();
  };
};