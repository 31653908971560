import React, { useState,useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Upload,
  Typography,
  Checkbox,
  Dropdown,
  Menu,
} from "antd";
import {
  ArrowLeftOutlined,
  EllipsisOutlined,
  FileAddOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import AddRecipients from "./AddRecipients";
import { UploadProps } from "antd/lib";
import MoreSettings from "./MoreSettings";
import { useForm } from "antd/es/form/Form";
import {
  currentApplicant,
  eSignDetails,
  Jd,
  Scope,
  ZohoRecipientAction,
} from "../../../../type/type.d";
import { getAllDocumentsByJd, getZohoEmbeddedUrl } from "../../../../lib/api";
import { connect } from "react-redux";
import TacitBaseModal from "../../../../ant/TacitbaseModal";
import { Overlay } from "../../../../components/hook/Overlay";
import { LabeledIconButton } from "../../../../ant/Button";
import { Dispatch } from "redux";
import { updateEsignDetails } from "../../../../redux/actions/opening/action";
import { throwFrontEndError } from "../../../../service/throwError";
// import TemplateModal from "./TemplateModal";
const { Text } = Typography;
const { TextArea } = Input;
type SendForSignaturesPageProps = {
  showModal: boolean;
  setShowModal: any;
  currentTrackerJd: Jd;
  currentApplicant: currentApplicant;
  setZohoESignLoading:any;
  updateEsignDetails: (data: eSignDetails) => void;
  name: string;
  email: string;
};
const SendForSignaturesPage = (props: SendForSignaturesPageProps) => {
  const [fileList, setFileList] = useState<any[]>([]);
  const [files, setFiles] = useState<any[]>([]);
  const [sendInOrder, setSendInOrder] = useState(false);
  const [loading, setLoading] = useState(false);

  const [currentWindow, setCurrentWindow] = useState<Window | null>(null);

  // const [isTemaplateVisible, setIsTemplateVisible] = useState(false);
  // const [template, setTemplate] = useState([]);

  // showing empty recipients to display at list one field on add recipients form item
  const [recipients, setRecipients] = useState<ZohoRecipientAction[]>([
    {
      action_type: "SIGN",
      recipient_email: props.email,
      recipient_name: props.name,
      signing_order: 1,
      verify_recipient: false,
    },
  ]);

  useEffect(() => {
    form.setFieldsValue({
      [`recipient_email_0`]: props.email,
      [`recipient_name_0`]: props.name ,
    });
  }, []);

  const handleCancelDocument = ()=>{
    form.resetFields();
    props.setShowModal(false);
  }
  // getting document templates from templatesmodel and adding them to file list
  // useEffect(() => {
  //   if (template.length > 0) {
  //     template.forEach((temp: any) => {
  //       form.setFieldsValue({
  //         documentName: temp.template_name,
  //       });

  //       if (temp.actions && temp.actions.length > 0) {
  //         // Properly set recipient form fields
  //         temp.actions.forEach((action: any, index: number) => {
  //           form.setFieldsValue({
  //             [`recipient_email_${index}`]: action.recipient_email,
  //             [`recipient_name_${index}`]: action.recipient_name,
  //           });
  //         });

  //         setRecipients(temp.actions);
  //       }
  //       setSendInOrder(temp.is_sequential);
  //       form.setFieldsValue({
  //         daysToComplete: temp.expiration_days,
  //         reminderEvery: temp.reminder_period,
  //         noteToAllRecipients: temp.notes,
  //       });
  //       const templateDocs = temp.document_ids.map((doc: any) => ({
  //         uid: doc.document_id,
  //         name: doc.document_name,
  //         size: doc.document_size,
  //         type: "application/pdf",
  //         isTemplate: true,
  //         document_id: doc.document_id,
  //       }));
  //       form.setFieldsValue({
  //         document: templateDocs
  //       });
  //       setFileList((prevList) => [...prevList, ...templateDocs]);
  //       setFiles((prevFiles) => [...prevFiles, ...templateDocs]);
  //     });
  //   }
  // }, [template]);

  
  const handleMessage = (event: MessageEvent) => {
    if (event.origin !== window.location.origin) return;
    if (event.data.type === "zoh_sign_doc_redirection") {
      props.setShowModal(false);
      form.resetFields();
      props.setZohoESignLoading(true);
      getAllDocumentsByJd(
        props.currentTrackerJd.id,
        props.currentApplicant.candidate_id
      )
        .then((res) => {
          props.setZohoESignLoading(false);
          if (res.document) {
            props.updateEsignDetails({ documents: res.document });
            return;
          }
        })
        .catch(() => {
          props.setZohoESignLoading(false);
          console.log("Failed to get all documents");
        });
      if (currentWindow && !currentWindow.closed) {
        currentWindow.close();
      }
      window.removeEventListener("message", handleMessage);
    }
  };

  const [form] = useForm();

  const propsUpload: UploadProps = {
    onRemove: (file) => {
      const newFileList = fileList.filter((item) => item.uid !== file.uid);
      setFileList(newFileList);
      form.setFieldsValue({ document: newFileList });
    },
    beforeUpload: (file) => {
      const isDuplicate = fileList.some((item) => item.uid === file.uid);
      if (isDuplicate) {
        return false;
      }
      setFiles((prevFiles) => [...prevFiles, file]);
      const fileUrl = URL.createObjectURL(file);
      const updatedFileList = [...fileList, { ...file, url: fileUrl }];
      setFileList(updatedFileList);
      form.setFieldsValue({ document: updatedFileList });
      return false;
    },
    fileList,
    showUploadList: false,
  };

  // checking if the file size is less than 10MB
  const fileSizeValidator = async (_: any, file: any) => {
    const currentFile = file?.file;
    if (!currentFile) {
      return Promise.resolve();
    }
    const isFileSizeValid = currentFile.size / 1024 / 1024 < 10;
    if (!isFileSizeValid && fileList.length > 0) {
      return Promise.reject(new Error("File must be smaller than 10MB!"));
    }
    return Promise.resolve();
  };

  // calling createFile api to get embedded url for zoho sign page.
  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        setLoading(true);
        const formData = new FormData();
        if (fileList.length > 0) {
          files.forEach((file) => {
            formData.append("file", file);
          });
        }

        const requestData = {
          requests: {
            request_name: values.documentName,
            is_sequential: sendInOrder,
            actions: recipients.filter((r) => r.recipient_email !== ""),
            expiration_days: values.daysToComplete || 10,
            email_reminders: true,
            reminder_period: values.reminderEvery || 2,
          },
        };
        formData.append("data", JSON.stringify(requestData));
        getZohoEmbeddedUrl(
          formData,
          props.currentApplicant.candidate_id,
          props.currentTrackerJd.id
        ).then((url) => {
          setLoading(false);
          const newCurrentWindow = window.open(
            url,
            "SaveEsignDocument",
            "width=600,height=600,resizable=yes,scrollbars=yes"
          );
          window.addEventListener("message", handleMessage);
          setCurrentWindow(newCurrentWindow);
        }).catch(()=>{
          setLoading(false);
        });
      })
  };

  // add action button to delete selected file
  const menu = (file: any) => (
    <Menu>
      <Menu.Item
        key="delete"
        onClick={() => {
          const newFileList = fileList.filter((f: any) => f.uid !== file.uid);
          setFileList(newFileList);
          form.setFieldsValue({ document: newFileList });
        }}
      >
        Delete
      </Menu.Item>
    </Menu>
  );

  /// add document menu to add document from template
  // const addDocumentMenu = (
  //   <Menu>
  //     <Menu.Item
  //       key="template"
  //       onClick={(e) => {
  //         e.domEvent.stopPropagation();
  //         setIsTemplateVisible(true);
  //       }}
  //     >
  //       From Template
  //     </Menu.Item>
  //   </Menu>
  // );

  return (
    <TacitBaseModal
      keyboard={false}
      maskClosable={false}
      visibility={props.showModal}
      width={"100%"}
      title={
        <>
          <Overlay
            onClose={() => props.setShowModal(false)}
            scope={Scope.Dialog}
          >
            <div
              className={`w-full flex justify-between py-2.5 border-b px-4`}
              style={{ height: 52 }}
            >
              <div>
                <LabeledIconButton
                  icon={<ArrowLeftOutlined />}
                  type="text"
                  className="p-0"
                  label="Go back"
                  onClick={(e) => {
                    e.stopPropagation();
                    props.setShowModal(false);
                  }}
                />
              </div>
            </div>
          </Overlay>
        </>
      }
      closeIcon={null}
      content={
        <>
          <div
            className="p-4 overflow-y-auto"
            style={{
              margin: 0,
              position: "relative",
              overflow: "hidden",
              width: "calc(100vw - 300px )",
              maxWidth: "calc(100vw - 300px )",
              height: "calc(100vh - 320px )",
              maxHeight: "calc(100vh - 320px )",
              overflowY: "auto",
              borderRadius: "4px",
            }}
          >
            <Form form={form} layout="vertical" className="space-y-6 h-full">
              <div className="space-y-4">
                <Form.Item
                  label="Add Documents"
                  name="document"
                  rules={[
                    { required: true, message: "Please upload a document!" },
                    { validator: fileSizeValidator },
                  ]}
                >
                  <div className="flex flex-wrap gap-4">
                    <div className="w-52 h-52 border-2 border-dashed border-gray-300 rounded-lg hover:border-green-500 transition-colors">
                      <Upload.Dragger
                        accept=".pdf"
                        {...propsUpload}
                        showUploadList={false}
                        fileList={fileList}
                        className="h-full flex flex-col justify-center items-center p-2"
                        onDrop={(e) => {
                          const droppedFiles = Array.from(e.dataTransfer.files);
                          const nonPdfFiles = droppedFiles.filter(file => file.type !== 'application/pdf');
                          if (nonPdfFiles.length > 0) {
                          throwFrontEndError('You can only upload PDF files');
                          return;
                          }
                          const newFiles = droppedFiles.filter(
                            file => !fileList.some(item => item.name === file.name)
                          );
                          
                          const updatedFileList = [
                            ...fileList,
                            ...newFiles.map(file => ({
                              ...file,
                              uid: `${Date.now()}-${file.name}`,
                              url: URL.createObjectURL(file)
                            }))
                          ];
                          
                          setFileList(updatedFileList);
                          setFiles(prev => [...prev, ...newFiles]);
                          form.setFieldsValue({ document: updatedFileList });
                        }}
                      >
                        <div className="text-center">
                          <FileAddOutlined className="text-3xl text-gray-500 mb-2" />
                          <Text type="secondary" className="block text-sm mb-2">
                            Drag file here
                          </Text>
                          <Text type="secondary" className="block text-xs mb-2">
                            or
                          </Text>
                          <Button
                            type="dashed"
                            icon={<UploadOutlined />}
                            size="small"
                            className="text-xs"
                          >
                            Add Document
                          </Button>
                        </div>
                      </Upload.Dragger>
                    </div>
                    {fileList.map((file, index) => (
                      <div
                        key={index}
                        className="w-52 h-52 border-2 border-dashed border-gray-300 rounded-lg relative overflow-hidden"
                      >
                        <div className="absolute top-2 right-2 z-10">
                          <Dropdown
                            overlay={() => menu(file)}
                            trigger={["click"]}
                          >
                            <Button
                              type="text"
                              icon={<EllipsisOutlined />}
                              className="hover:bg-gray-100 rounded-full"
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            />
                          </Dropdown>
                        </div>
                        {file.url && (
                          <div className="w-full h-full">
                            <iframe
                              src={`${file.url}#toolbar=0&navpanes=0`}
                              title="PDF Preview"
                              className="w-full h-full object-cover"
                            />
                          </div>
                        )}
                        <Text
                          type="secondary"
                          className="text-center p-2 truncate block text-xs"
                        >
                          {file.name}
                        </Text>
                      </div>
                    ))}
                  </div>
                </Form.Item>
              </div>

              <div className="space-y-4">
                <Form.Item
                  name="documentName"
                  label="Document Name"
                  className="w-1/2"
                  rules={[
                    {
                      required: true,
                      message: "Please provide a document name!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Enter Document Name"
                    className="rounded-md"
                  />
                </Form.Item>
              </div>

              <div className="space-y-4">
                <Text>Add Recipients</Text>
                <div className="flex items-center space-x-4">
                  <Button
                    className="flex items-center space-x-2"
                    icon={
                      <Checkbox
                        checked={sendInOrder}
                        onChange={(e) => setSendInOrder(e.target.checked)}
                      />
                    }
                  >
                    Send in Order
                  </Button>
                </div>
                <Form.Item
                  label="Recipients"
                  name="recipients"
                  className="mt-4"
                >
                  <AddRecipients
                  form={form}
                    recipients={recipients}
                    setRecipients={setRecipients}
                  />
                </Form.Item>
              </div>

              <Form.Item>
                <MoreSettings />
              </Form.Item>

              <div className="space-y-4 w-1/2">
                <Form.Item
                  name="noteToAllRecipients"
                  label="Note to all recipients"
                >
                  <TextArea
                    autoSize={{ minRows: 3, maxRows: 5 }}
                    className="rounded-md focus:border-green-500 focus:ring-1 focus:ring-green-500"
                    placeholder="Add a note for your recipients (optional)"
                  />
                </Form.Item>
              </div>
            </Form>
          </div>
        </>
      }
      footer={
        <div className="flex justify-end border-t  space-x-4">
          <div className="px-4 py-2 space-x-4">
            <Button onClick={handleCancelDocument}>Cancel</Button>
            <Button type="primary" loading={loading} onClick={handleSubmit}>
              Next
            </Button>
          </div>
        </div>
      }
      style={{
        overflowY: "hidden",
        width: " 100vw",
        height: "100vh",
        position: "fixed",
        top: 70,
        left: "10px",
        bottom: "10px",
        right: "0px",
        display: "flex",
        alignItems: "start",
        justifyContent: "center",
        padding: "0px",
        borderRadius: "4px",
      }}
      className="review-modal-content"
    />
  );
};
const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateEsignDetails: (data: eSignDetails) => dispatch(updateEsignDetails(data)),
});

const mapStateToProps = (state: any) => ({
  currentTrackerJd: state.opening.currentTrackerJd,
  currentApplicant: state.opening.currentApplicant,
});
export default connect(mapStateToProps,mapDispatchToProps)(SendForSignaturesPage);
