import React, { useEffect, useState } from "react"
import { Popover, Input, Typography, Space, Tag } from "antd"
import { CloseOutlined, DownOutlined } from "@ant-design/icons"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { LabeledIconButton } from "../../ant/Button"
import { Opening, OrganizedBoard, Scope, companyBoards } from "../../type/type.d"
import { Overlay } from "../hook/Overlay"
import { connect } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import { JOB_POSTS } from "../../constant"

const { Text } = Typography

interface Props {
    currentOpening: Opening,
    companyBoard: { [key: string]: companyBoards },
    openings: OrganizedBoard,
}

const BoardSearchPopover: React.FC<Props> = ({
    currentOpening,
    companyBoard,
    openings
}) => {
    const history: any = useHistory()
    const params: { board_id: string, org_id: string } = useParams()

    const [visible, setVisible] = useState(false)
    const [boardSearchValue, setBoardSearchValue] = useState("")
    const [filteredBoards, setFilteredBoards] = useState<string[]>([])

    useEffect(() => {
        companyBoard[currentOpening.company_id] &&
            setFilteredBoards(companyBoard[currentOpening.company_id].boards)
    }, [companyBoard])

    const handleBoardSearch = (value: string) => {
        const results: string[] = companyBoard[currentOpening.company_id].boards.filter((bid: string) =>

            openings.pageSection.openings[bid].name.toLowerCase().includes(value.toLowerCase())
        )

        setBoardSearchValue(value)
        setFilteredBoards(results)
    }

    const hide = () => {
        setVisible(false)
        setBoardSearchValue("")
    }

    const handleBoardClick = (boardId: string) => {
        setVisible(false) // Close the popup when a board is clicked
        setTimeout(() => {
            history.push({
                pathname: `/o/${params.org_id}/b/${boardId}/tracker`,
                method: "get",
            })
        }, 500) // 1000 milliseconds = 1 second delay
    }
    const renderCompanyBoards = boardSearchValue ?
        (
            <div className="h-auto tacitbase-member-popover max-h-72 overflow-y-scroll py-1 overflow-hidden">
                <Space direction="vertical" className="w-full">
                    {filteredBoards
                    .slice()
                    .sort((a, b) => {
                        const nameA = openings.pageSection.openings[a].name.toLowerCase();
                        const nameB = openings.pageSection.openings[b].name.toLowerCase();
                        return nameA.localeCompare(nameB);
                    })
                    .map((bid: string) =>
                    (
                        <Tag key={bid}
                            className="w-full border-0 bg-white hover:bg-gray-100 py-1 flex items-center  justify-center h-auto cursor-pointer"
                            onClick={() => handleBoardClick(bid)}>
                            <Space className="w-full">
                                <div
                                    className={`bg-color-${openings.pageSection.openings[bid].color} rounded bg-cover bg-center object-contain`}
                                    style={{
                                        width: "45px",
                                        height: "30px",
                                        backgroundImage: openings.pageSection.openings[bid].background_image ? `url(${openings.pageSection.openings[bid].color})` : "none",
                                        backgroundColor: openings.pageSection.openings[bid].background_image ? `none` : openings.pageSection.openings[bid].color,
                                    }}
                                />
                                <Text ellipsis style={{ minWidth: 100, maxWidth: 150 }}>{openings.pageSection.openings[bid].name}</Text>
                            </Space>
                        </Tag>
                    )
                    )}
                </Space>
            </div>
        )
        :
        companyBoard[currentOpening.company_id] && (
            <div className="h-auto tacitbase-member-popover max-h-72 overflow-y-scroll py-1 overflow-hidden">
                <Space direction="vertical" className="w-full">
                    {companyBoard[currentOpening.company_id].boards
                    .slice()
                    .sort((a, b) => {
                        const nameA = openings.pageSection.openings[a]?.name?.toLowerCase() || "";
                        const nameB = openings.pageSection.openings[b]?.name?.toLowerCase() || "";
                        return nameA.localeCompare(nameB);
                    })    
                    .map((bid: string) =>
                    (
                        <Tag key={bid}
                            className="w-full border-0 bg-white hover:bg-gray-100 py-1 flex items-center  justify-center h-auto cursor-pointer"
                            onClick={() => handleBoardClick(bid)}>
                            <Space className="w-full">
                                <div
                                    className={`bg-color-${openings.pageSection.openings[bid].color} rounded bg-cover bg-center object-contain`}
                                    style={{
                                        width: "45px",
                                        height: "30px",
                                        backgroundImage: openings.pageSection.openings[bid].background_image ? `url(${openings.pageSection.openings[bid].color}&w=30&dpr=2)` : "none",
                                        backgroundColor: openings.pageSection.openings[bid].background_image ? `none` : openings.pageSection.openings[bid].color,
                                    }}
                                />
                                <Text ellipsis style={{ minWidth: 100, maxWidth: 150 }}>{openings.pageSection.openings[bid].name}</Text>
                            </Space>
                        </Tag>
                    )
                    )}
                </Space>
            </div>
        )
    const contentDefault = (
        <>
            <div className="w-72 overflow-hidden">
                <Space direction="vertical" className="w-full">
                    <Input.Search
                        placeholder="Search job posts"
                        value={boardSearchValue}
                        onChange={(e) => handleBoardSearch(e.target.value)}
                        onSearch={handleBoardSearch}
                    />
                    {renderCompanyBoards}
                </Space>

            </div>
        </>
    )

    const titleContentDefault = (
        <div className="flex w-full bg-white items-center">
            <Text className="w-full flex justify-center">
                <Text>{capitalizeFirstLetter(JOB_POSTS)}</Text>
            </Text>
            <LabeledIconButton
                type="text"
                label=""
                onClick={hide}
                icon={<CloseOutlined />}
            />
        </div>
    )

    return (
        <div className="tacitbase-member-popover">
            <Popover
                title={titleContentDefault}
                content={visible ? <Overlay onClose={hide} scope={Scope.Popover}>{contentDefault}</Overlay> : null}
                trigger="click"
                visible={visible}
                onOpenChange={(isVisible) => setVisible(isVisible)}
                className="tacitbase-member-popover w-full"
            >
                <div className="px-2 pt-1.5 pb-1 rounded hover:rounded hover:bg-black hover:bg-opacity-30">
                    <DownOutlined style={{color:'white'}}/>
                </div>
            </Popover>
        </div>
    )
}

const mapStateToProps = (state: any) => ({
    currentOpening: state.opening.currentOpening,
    companyBoard: state.opening.companyBoards,
    openings: state.opening.openings,
})

export default connect(mapStateToProps)(BoardSearchPopover)
