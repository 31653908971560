import React, { useEffect, useState } from "react";
import { Tabs } from "antd";
import Comments from "../comment/CardHistory";
import CommentInput from "../comment/commentInput/CommentInput";
import ShowComments from "../comment/commentInput/ShowComments";
// import CardMailPreview from "../comment/CardMailPreview";
import { getActivity, getAllDocumentsByJd, getApplicantComments, getRecordMailPreview, getTrackercardEvents, getUserThreadOfMessage } from "../../../lib/api";
import { connect, useDispatch, useSelector } from "react-redux";
import {  getSelectedMailThread, initComments, setCardCalEvents, updateEsignDetails } from "../../../redux/actions/opening/action";
import { Dispatch } from "redux";
import {  eSignDetails, Jd, } from "../../../type/type";
import { ACTIVITY_TAG, COMMENT_TAG, EMAIL_TAG } from "../../../constant";
import CardDetailMail from "../comment/CardDetailMail";
import CardEvents from "../CardEvents/CardEvents";
import { useParams } from "react-router-dom";
import { getUserTimezone } from "../../calender/global/calendarUtils";
import { RootState } from "../../../redux/reducers/root";
import { calendarListState } from "../../../redux/reducers/calendarSlice";
import { CalendarItem, Calendars } from "../../../lib/apiRes";
import useTransformEventsResponse from "../CardEvents/useTransformEventsResponse";
import {  currentApplicant, RecordThreads } from "../../../type/type";
import ShowDocumentStatus from "../../../styles/component/ESign/showDocumentStatus";

type ActivityTabProps = {
  currentApplicant: currentApplicant;
  eSignDetails:eSignDetails;
  currentTrackerJd:Jd;
  initComments(comments: any, tag: string, applicant_id: string): void;
  getSelectedMailThread: (currentMailId: string,threadMsg: RecordThreads) => void;
  updateEsignDetails: (data: eSignDetails) => void;
}
const ActivityTab = (props: ActivityTabProps) => {
  const params: { card_id: string } = useParams();
  const dispatch = useDispatch();
  const loginUser = useSelector((state: RootState) => state.opening.loginUser); // Access loginUser from Redux
  let calendarList: calendarListState= useSelector((state: RootState) => state.calendar.calendarList);
  const [loading, setLoading] = useState(true); // Initialize loading state

  const teamCalendar =Object.values(calendarList).find((calendar: CalendarItem) => calendar.is_team_calendar)|| Object.values(calendarList).find((calendar: Calendars) => calendar.primary);

  const [docLoading, setDocLoading] = useState(false);
  const [ActiveKey, setActiveKey] = useState("comments");
  useEffect(()=>{
    setActiveKey("comments");
  },[params.card_id])
  const handleTabChange = (key:string)=>{
    setActiveKey(key);
    if (props.currentApplicant.id) {
      if(key === "comments"){
        getApplicantComments(props.currentApplicant.id)
        .then((res) => {
          // Call initComments after successfully fetching commentssetLoading
          props.initComments(res, COMMENT_TAG, props.currentApplicant.id);
        })
        .catch((error) => {
          // Handle any errors here
          console.error("Failed to fetch applicant comments:", error);
        });
      }
      if(key === "email"){
        getRecordMailPreview(props.currentApplicant.id).then((res) => {
          props.initComments(res.items, EMAIL_TAG, props.currentApplicant.id);
        });
        getUserThreadOfMessage(props.currentApplicant.id).then((res) => {
          props.getSelectedMailThread(props.currentApplicant.id, res);
        });
      }
      if(key === "activity"){
        if (props.currentApplicant.id) {
          getActivity("cards", params.card_id).then((res) => {
            if (res) {
              props.initComments(res, ACTIVITY_TAG, props.currentApplicant.id);
            }
          });
        }
       
      }
      if (key == 'Events') {
        const loadBookings = async () => {
          setLoading(true); // Set loading to true before fetching
          try {
              const response = await getTrackercardEvents(teamCalendar.id, loginUser.user.id, "100",  props.currentApplicant.id,getUserTimezone().name);
              dispatch(setCardCalEvents(useTransformEventsResponse(response)));
          } catch (error) {
              console.error("Error fetching bookings:" , error);
          } finally {
              setLoading(false); // Set loading to false after fetching
          }
      };

      loadBookings();
      }
      if(key === "documents"){
        if (props.eSignDetails.connected) {
          setDocLoading(true);
          getAllDocumentsByJd(props.currentTrackerJd.id, props.currentApplicant.candidate_id)
            .then((res) => {
              if (res.document) {
                setDocLoading(false);
                props.updateEsignDetails({ documents: res.document });
                return;
              }
              setDocLoading(false);
            })
            .catch(() => {
              setDocLoading(false);
              console.log("Failed to get all documents");
            });
        }
      }
    }
  }

  const items = [
    {
      key: "comments",
      label: <div>Comments</div>,
      children: (
        <div
          className="flex flex-col"
          style={{
            height: "calc(100vh - 225px )",
          }}
        >
          <div className="overflow-y-auto h-full">
            <ShowComments />
          </div>
          <div className="mt-auto">
            <CommentInput />
          </div>
        </div>
      ),
    },
    {
      key: "email",
      label: "Email",
      children: (
        <div
          style={{
            height: "calc(100vh - 225px )",
          }}
        >
          <CardDetailMail />
        </div>
      ),
    },
    {
      key: "activity",
      label: "Activity",
      children: (
        <div
          className="overflow-y-scroll"
          style={{
            height: "calc(100vh - 225px )",
          }}
        >
          {" "}
          <Comments type="activity" />{" "}
        </div>
      ),
    },
    {
      key: "Events",
      label: "Events",
      children: (
        <div
          className="overflow-y-scroll"
          style={{
            height: "calc(100vh - 225px )",
          }}
        >
          {" "}
          <CardEvents loading={loading} />{" "}        </div>
      ),
    },
    {
      key: "documents",
      label: "E-sign",
      children: (
        <div
          className="overflow-y-auto"
          style={{
            height: "calc(100vh - 225px )",
          }}
        >
          <ShowDocumentStatus docLoading={docLoading} />
        </div>
      ),
    },

  ];
  return (
    <Tabs className="card-activity-tab" onChange={handleTabChange} activeKey={ActiveKey} defaultActiveKey="comments" items={items} />
  );
};

const mapStateToProps = (state: any) => ({
  currentApplicant: state.opening.currentApplicant,
  comments: state.opening.comments,
  members: state.opening.members,
  eSignDetails: state.opening.eSignDetails,
  currentTrackerJd: state.opening.currentTrackerJd,
});
const mapPropsToState = (dispatch: Dispatch) => ({
  initComments: (comments: any, tag: string, applicant_id: string) =>
    dispatch(initComments(comments, tag, applicant_id)),
  getSelectedMailThread: (currentMailId: string, threadMsg: RecordThreads) =>
    dispatch(getSelectedMailThread(currentMailId, threadMsg)),
  updateEsignDetails: (data: eSignDetails) => dispatch(updateEsignDetails(data)),

});
export default connect(mapStateToProps, mapPropsToState)(ActivityTab);
