import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Attachment, currentApplicant, GetOpening } from "../../../type/type";
import {
    Button,
    Dropdown,
    Space,
    Tag,
    Typography,
    Popover,
    Checkbox,
    Divider,
    Upload,
    message,
    Menu,
} from "antd";
import { PlusOutlined, EllipsisOutlined, FilePdfOutlined, CloseOutlined } from "@ant-design/icons";
import { PDFRenderer } from "../PDFViewer";
import { ATTACHMENTS, MAX_ATTACHMENT_SIZE, SOCKET_CLIENT_ID } from "../../../constant";
import { IconButton, LabeledIconButton } from "../../../ant/Button";
import OpenFileInNewTab from "../attachement/OpenFileInNewTab";
import RemoveAttachementOfEvent from "./RemoveAttachementOfEvent";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { buildFormData } from "../../../utils/FormDataBuilder";
import { addAttachment } from "../../../lib/api";
import { throwFrontEndError } from "../../../service/throwError";
import { useParams } from "react-router-dom";

const { Text } = Typography;

const CardAttachment = (props: {attachment?: any;
    currentApplicant: currentApplicant;
    initAttachment?: any;
    clientId: string;
    onAttachmentsChange: (attachments: any[]) => void;
    currentOpening: GetOpening;
    eventData:any
}) => {
    const params: {
        event_id: string;
        card_id: string;
        org_id: string;
        board_id: string;
        calendar_id: string;
      } = useParams();
    const [jdAttachments, setJDAttachments] = useState<Attachment[]>(props.initAttachment);
    const [selectedCandidateAttachment, setSelectedAttachment] = useState<Attachment>(props.initAttachment);
    const [selectedAttachments, setSelectedAttachments] = useState<Attachment[]>(props.initAttachment);
    const [activePopover, setActivePopover] = useState<"main" | "remaining" | null>(null);
    const [fileExceed, setFileExceed] = useState(false);
    const [setLoader,setAttachmentoader] = useState(false);

    useEffect(() => {
        setJDAttachments(props.initAttachment)
    }, [props.initAttachment]);

    const handleSelectedAttachment = (attachment: Attachment) => {
        setSelectedAttachment(attachment);
    };

    const onCloseViewer = () => {
        setSelectedAttachment(props.initAttachment);
    };

    const handleCheckboxChange = (attachment: Attachment) => {
        setSelectedAttachments((prev) =>
            prev.some((item) => item.id === attachment.id)
                ? prev.filter((item) => item.id !== attachment.id)
                : [...prev, attachment]
        );
    };

    // Handle attachment removal
    const removeAttachment = async (attachmentId: string) => {        
        try {
            // Filter out the attachment being removed
        const updatedJDAttachments = jdAttachments.filter((attachment) => attachment.id !== attachmentId);
        setSelectedAttachments((prev) => prev.filter((attachment) => attachment.id !== attachmentId));

        // Update local states
        setJDAttachments(updatedJDAttachments);

        // Notify parent component with the updated attachments
        props.onAttachmentsChange([...updatedJDAttachments]);
            // You can also call an API to remove the attachment on the backend
            // await removeAttachmentFromBackend(attachmentId);
        } catch (error) {
            message.error("Failed to remove attachment.");
        }
    };

    const renderMenu = (attachment: Attachment) => (
        <Menu>
            <div
                className="flex flex-col items-start space-y-2 p-2"
                role="button"
                tabIndex={0}
                onClick={(e) => e.stopPropagation()}
                onKeyDown={(e) => {
                    if (e.keyCode === 13 || e.metaKey) {
                        e.stopPropagation();
                    }
                }}
            >
                <div>
                    <RemoveAttachementOfEvent
                        attachment={attachment}
                        removeAttachment={() => removeAttachment(attachment.id)}
                    />
                </div>
                <div>
                    <OpenFileInNewTab attachment={attachment} />
                </div>
            </div>
        </Menu>
    );

    const handleInsertAttachments = () => {
        setJDAttachments((prev) => [...prev, ...selectedAttachments]);
        setActivePopover(null);
        props.onAttachmentsChange([...jdAttachments,...selectedAttachments])

    };
    const handleFileChange = (info: any) => {
      
      const fileList = info.fileList;
      if (!fileList || fileList.length === 0) return;
    
      const file = fileList[0].originFileObj;
    
      if (file.size > MAX_ATTACHMENT_SIZE) {
        setFileExceed(true);
      } else {
        setFileExceed(false);
        uploadFile(fileList.map((file: any) => file.originFileObj));
      }
    };
        
  
    const uploadFile = (files: FileList) => {
    const recordId = params.card_id ? props.currentApplicant.id : props.eventData.extended_properties.private.card_id;

    const data = [
        { key: 'model_name', value: 'events' },
        { key: 'record_id', value: recordId},
        { key: 'opening_id', value: props.currentOpening.id },
        { key: 'socket_client_id', value: sessionStorage.getItem(SOCKET_CLIENT_ID) || '' },
        // { key: 'list_id', value: props.currentApplicant.list_id }
      ];
      const formData = buildFormData(data, files);
      setAttachmentoader(true);
      addAttachment(formData, recordId)
        .then((res) => {
            setAttachmentoader(false);
            setActivePopover(null)
            const newAttachment = {
            id: res.attachment[0].id, // Generate a unique ID
            name: res.attachment[0].name,
            presignedLink:res.attachment[0].presignedLink
        };
        props.onAttachmentsChange([...jdAttachments,newAttachment])

        setJDAttachments((prev: any) => [...prev, newAttachment]); // Add to the local list
        })
        .catch(() => {
        setAttachmentoader(false);
        setActivePopover(null)
        throwFrontEndError('Error while uploading attachment')
        });
    };
  
    const renderPopoverContent = () => (
        <div style={{ maxWidth: "270px" }}>
            <div className="flex w-full bg-white justify-between items-center">
                <LabeledIconButton
                    type="text"
                    label=""
                // onClick={() => handleBackEvent(false)}
                // icon={<LeftOutline />}
                />
                <Text>{capitalizeFirstLetter("Attachment")}</Text>
                <LabeledIconButton
                    type="text"
                    label=""
                    // onClick={hide}
                    icon={<CloseOutlined />}
                />
            </div>
            <Divider className="my-2" />
            {fileExceed && (
                <div className="bg-red-50 p-4 mx-2">{MAX_ATTACHMENT_SIZE}</div>
            )}
            <Text type="secondary">Attach a file from your computer</Text>

            <Upload
                className='flex card-file-upload w-full'
                showUploadList={false}
                beforeUpload={() => false} // Disable default behavior to handle manually
                onChange={handleFileChange}
                fileList={[]}
            >
                <Button block size="middle" type="dashed">
                    Upload from Computer
                </Button>
            </Upload>
            {params.card_id && props.attachment[props.currentApplicant.id].length > 0 &&
                <>        
                    <Divider className="my-2" />
                    <Text type="secondary">Suggested file</Text>

                    <div
                        style={{
                            maxHeight: "200px",
                            overflowY: props.attachment[props.currentApplicant.id]?.length > 5 ? "auto" : "visible",
                            marginBottom: "12px",

                        }}
                        className="flex justify-start flex-col w-full items-center"
                    >
                        {params.card_id && props.attachment[props.currentApplicant.id]?.map((attachment: any) => (
                            <div key={attachment.id} className="w-full flex justify-start items-center space-x-2 ">
                                <Checkbox
                                    key={attachment.id}
                                    onChange={() => handleCheckboxChange(attachment)}
                                    checked={selectedAttachments.some((item) => item.id === attachment.id)}
                                >
                                </Checkbox>

                                <span
                                    className="flex items-center justify-center"
                                    style={{
                                        maxWidth: "245px",
                                        display: "inline-block",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                    }}
                                    title={attachment.name} // Tooltip for full name
                                >
                                    {attachment.name}
                                </span>
                            </div>
                        ))}
                    </div>
                    <Button
                        type="primary"
                        block
                        size="middle"
                        className="mt-2"
                        loading={setLoader}
                        onClick={handleInsertAttachments}
                    >
                        Save
                    </Button>
                </>

            }
        </div>
    );
    
    
    // if (!props.attachment[props.currentApplicant.id]) {
    //     return null;
    // }

    const displayedAttachments = [...jdAttachments].slice(0, 3);
    const remainingCount = jdAttachments.length - 3;
    return (
        <div>
                <Space direction="vertical" className="w-full mb-2">
                    <Space>
                        <Text className="font-medium">{ATTACHMENTS}</Text>
                        <Popover
                            content={renderPopoverContent}
                            open={activePopover === "main"}
                            onOpenChange={(visible) => setActivePopover(visible ? "main" : null)}
                            trigger="click"
                        >
                            <IconButton size="small" icon={<PlusOutlined />} />
                        </Popover>
                    </Space>
  
                    <div className="flex gap-2 pt-2 w-full flex-wrap">
                    {displayedAttachments
                        .map((attachment: any) => (
                            <Tag
                                className="cursor-pointer flex items-center border border-black border-opacity-5 p-2 h-10"
                                style={{ width: "46.5%" }}
                                bordered={false}
                                key={attachment.id}
                                onClick={() => handleSelectedAttachment(attachment)}
                            >
                                <FilePdfOutlined style={{ fontSize: "22px" }} />
                                <Text ellipsis={{ tooltip: attachment.name }} style={{ width: "100%" }}>
                                    {attachment.name|| attachment.title}
                                    </Text>
                                    {attachment.modelName === "Jd" && <Tag color="blue">JD</Tag>}
                                <Dropdown overlay={renderMenu(attachment)} trigger={["click"]}>
                                    <Button
                                        type="text"
                                        tabIndex={-1}
                                        icon={<EllipsisOutlined />}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                        }}
                                    />
                                </Dropdown>
                            </Tag>
                        ))}
                        {remainingCount > 0 && (
                            <Popover
                                content={renderPopoverContent}
                                open={activePopover === "remaining"}
                                onOpenChange={(visible) => setActivePopover(visible ? "remaining" : null)}
                                trigger="click"
                            >
                                <Tag
                                    className="cursor-pointer flex items-center border border-black border-opacity-5 p-2 h-10"
                                    onClick={() => setActivePopover("remaining")}
                                >
                                    <Text>+{remainingCount}</Text>
                                </Tag>
                            </Popover>
                        )}
                    </div>
                </Space>
            {selectedCandidateAttachment?.id?.length > 0 && (
                <PDFRenderer
                    title={selectedCandidateAttachment.name}
                    attachmentOfwhom={props.currentApplicant.name}
                    onCloseViewer={onCloseViewer}
                    url={selectedCandidateAttachment.presignedLink}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state: any) => ({
    attachment: state.opening.attachment,
    currentApplicant: state.opening.currentApplicant,
    attachmentLoader: state.opening.attachmentLoader,
    clientId: state.opening.clientId,
    currentOpening: state.opening.currentOpening,

});

export default connect(mapStateToProps)(CardAttachment);
