import React, { useMemo } from "react";
import { Select } from "antd";
import dayjs from "dayjs";
import { ClockCircleOutlined } from "@ant-design/icons";

interface CustomTimePickerProps {
  mode: "start" | "end";
  value?: string;
  startTime?: string; // Only used when mode is "end"
  endTime?: string; // Only used when mode is "end"
  onChange: (value: string) => void;
  placeholder?: string;
  startDate?: dayjs.Dayjs,
  endDate?: dayjs.Dayjs
}

const generateTimeOptions = (
  mode: "start" | "end",
  startTime?: string,
  startDate?: dayjs.Dayjs,
  endDate?: dayjs.Dayjs
) => {
  const options: { value: string; label: string }[] = [];
  const startDayjs = startDate && startTime ? dayjs(`${startDate.format("YYYY-MM-DD")} ${startTime}`, "YYYY-MM-DD hh:mm A") : null;
  const seenKeys = new Set<string>();

  // If endDate is not provided, default to 1 day after startDate
  const adjustedEndDate = endDate ? endDate : startDate ? startDate.add(1, "day") : dayjs().add(1, "day");

  if (startDate && endDate && !startDate.isSame(endDate, "day")) {
    // Case: startDate and endDate are not the same day
    const currentDay = adjustedEndDate.startOf("day");

    for (let hour = 0; hour < 24; hour++) {
      for (let minute = 0; minute < 60; minute += 15) {
        const current = currentDay.add(hour, "hour").add(minute, "minute");
        const value = current.format("hh:mm A");

        // Avoid duplicates
        const key = `${value}-${value}`;
        if (!seenKeys.has(key)) {
          seenKeys.add(key);
          options.push({ value, label: value });
        }
      }
    }
  } else {
    // Case: startDate and endDate are the same day, or no endDate provided
    const effectiveStartDate = startDate || dayjs().startOf("day");
    const daysDifference = adjustedEndDate.diff(effectiveStartDate, "day");

    for (let dayOffset = 0; dayOffset <= daysDifference; dayOffset++) {
      const currentDay = effectiveStartDate.add(dayOffset, "day");

      for (let hour = 0; hour < 24; hour++) {
        for (let minute = 0; minute < 60; minute += 15) {
          const current = currentDay.add(hour, "hour").add(minute, "minute");
          const value = current.format("hh:mm A");
          let label = value;

          if (mode === "end" && startDayjs) {
            // For the same day, show times after the start time
            if (dayOffset === 0 && !current.isAfter(startDayjs)) continue;

            // Calculate the difference for the same day
            if (dayOffset === 0) {
              const diffMinutes = current.diff(startDayjs, "minute");
              if (diffMinutes > 0) {
                const hours = Math.floor(diffMinutes / 60);
                const minutes = diffMinutes % 60;
                const diffLabel = `${hours > 0 ? `${hours} hr${hours > 1 ? "s" : ""} ` : ""}${minutes > 0 ? `${minutes} min` : ""}`;
                label = `${value} (${diffLabel})`;
              }
            }
          }

          // Avoid duplicates
          const key = `${value}-${label}`;
          if (!seenKeys.has(key)) {
            seenKeys.add(key);
            options.push({ value, label });
          }
        }
      }
    }
  }

  return options;
};

const CustomTimePicker: React.FC<CustomTimePickerProps> = ({
  mode,
  value,
  startTime,
  onChange,
  startDate,
  endDate,
  placeholder = "Select Time"
}) => {
  const options = useMemo(
    () => generateTimeOptions(mode, startTime,startDate,endDate),
    [mode, startTime,startDate,endDate]
  );

  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      style={{ width: "130px" }}
      suffixIcon={<ClockCircleOutlined style={{ color: 'black', fontSize: "14px" }} />}
      dropdownStyle={{ width: mode === "end" ? '200px' : '150px' }}
      allowClear
    >
      {options.map((option) => (
        <Select.Option key={option.value} value={option.value}>
          {option.label}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CustomTimePicker;
