import { Card, List, Typography } from "antd";
import React from "react";
const { Text } = Typography;
export interface ListItem {
  label: string;
  value: string | number | string[] | null | undefined;
}

interface TableViewProps {
  listItems: ListItem[];
}

const isAnyValuePresent = (items: ListItem[]): boolean => {
  if (!Array.isArray(items)) return false;

  return items.some((item) => {
    const value = item.value;
    return (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      (!Array.isArray(value) || value.length > 0)
    );
  });
};

const formatValue = (value: ListItem["value"]): string => {
  if (Array.isArray(value)) {
    return value.join(", ");
  }
  return value?.toString() || "";
};

export const CandidateAiDetailView: React.FC<TableViewProps> = ({
  listItems,
}) => {
  if (!listItems || !isAnyValuePresent(listItems)) {
    return null;
  }

  return (
    <Card>
      <List>
        {listItems.map(({ label, value }) =>
          value ? (
            <List.Item
              key={label}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Text
                className="font-semibold"
                style={{ minWidth: "150px", flexShrink: 0 }}
              >
                {label}
              </Text>
              <Text style={{ flex: 1 }}>{formatValue(value)}</Text>
            </List.Item>
          ) : null
        )}
      </List>
    </Card>
  );
};

export default CandidateAiDetailView;
