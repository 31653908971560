import { useHistory } from "react-router-dom";

export const useTracker = () => {
  const history = useHistory();

  const handleTracker = (applicationId: string, id: string, card_id: string) => {
    history.push({
      pathname: `/o/${applicationId}/b/${id}/tracker/${card_id}`,
    });
  };

  const handleReview = (applicationId:string,id:string) => {
    history.push({
      pathname: `/o/${applicationId}/reviews/${id}`,
    });
  };

  return { handleTracker,handleReview };
};