import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import {
  Attachment,
} from "../../../type/type.d";
import UploadedFile from "./ShowProgress";
import DynamicMailTag from "./DynamicMailTag";
import SetSubject from "../../setSubject/SetEmailsubject";
import {
  Avatar,
  Button,
  ConfigProvider,
  Dropdown,
  Menu,
  Skeleton,
  Tag,
  Tooltip,
} from "antd";
import {
  BoldOutlined,
  CloseOutlined,
  DeleteOutlined,
  FunctionOutlined,
  ItalicOutlined,
  SendOutlined,
  UnderlineOutlined,
} from "@ant-design/icons";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import Chatbox from "./MailChatBox";
import GPT from "../../../assets/GPT";
import { customFormats, customModules } from "../../../constant";
// import EditorSkeleton from "../../JdManagement/EditorSkeleton";
import MemberPopup from "./MemberPopup";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import { LabeledIconButton, UpgradeButton } from "../../../ant/Button";
import { useOrgMetadata } from "../../../pages/setting/billingSetting/OrgMetadata";
import UpgradePlanModal from "../../../pages/setting/billingSetting/UpgradePlanModal";
import { setHeight, validation } from "./mailBoxHeight";
import { ComposeMailType, useMailSend } from "./useMailSend";
import { uploadFile } from "./fileUploadHandler";
import { handleBillingModal, handleBillingModalCancel } from "../../../pages/setting/billingSetting/billingModal";
import AttachmentIcon from "../../Icons/AttachmentIcon";
import Editor from "../../../ant/Editor/Editor";


const ComposeMail = (props: ComposeMailType) => {
  const [ccMembers, setCcMember] = useState<string[]>([]);
  const [bccMembers, setBccMember] = useState<string[]>([]);
  const [addCC, setCC] = useState<boolean>(false);
  const [addBcc, setBcc] = useState<boolean>(false);
  const [mailBody, setMailBody] = useState<string>("");
  const [mailTextBody, setMailTextBody] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
  const [showMoreToAddress, setShowMoreToAddress] = useState<boolean>(false);
  const [dynamicKeys] = useState(["first_name", "last_name"]);
  const [selectedDynamicKeys, addDynamicKeys] = useState<string[]>([]);
  const [uploadedAttchments, setUploadedAttachments] = useState<Attachment[]>(
    []
  );
  let fileInput = useRef<HTMLInputElement>(null);
  const quillRef = useRef<ReactQuill | null>(null);
  const { sentMailAnyway, isEditable } = useMailSend(props);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const orgMetadata = useOrgMetadata(props.currentOrg);

  const [chatBox, setShowChatBox] = useState(false);

  const handleChatBoxFromParent = (value: boolean) => {
    setShowChatBox(value);
  };

  const handleLoading = (value: boolean) => {
    setLoading(value);
  };
  const [loading, setLoading] = useState(false);

  const handleFileUpload = (file: any) => {
    uploadFile(file, props, uploadedFiles, setUploadedFiles, setUploadedAttachments, uploadedAttchments);
  };


  // Function to set the editor ready
  const setEditorReady = () => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      editor.focus(); // Optional: To set focus on the editor
      editor.insertText(0, ""); // Insert empty text if needed like Trix
    }
  };

  const handleChange = (content: string) => {
    setMailBody(content);
    if (props.getJobDescription) {
      props.getJobDescription(content);
    }
    setShowMoreToAddress(false);
    let updatedDynamicKeys: string[] = dynamicKeys.filter((key) =>
      content.includes(key)
    );
    addDynamicKeys([...updatedDynamicKeys]);
    // Access the Quill editor instance via ref and get plain text
    if (quillRef.current) {
      const editor = quillRef.current.getEditor(); // Get the Quill instance from ReactQuill
      const plainText = editor.getText(); // Get the plain text
      setMailTextBody(plainText); // Set the plain text content
    }
  };

  useEffect(() => {
    const toolbar = document.getElementsByClassName("ql-toolbar");
    if (toolbar && toolbar[0]) {
      // Optionally remove any toolbar element you don't want
      // Example: remove the 'image' button
      const imageButton = toolbar[0].querySelector("button.ql-image");
      if (imageButton) imageButton.remove();
    }
  }, []);

  // Function to insert dynamic key at cursor position
  const handleInsertValue = (key: string) => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(); // Get current cursor position
      if (range) {
        editor.insertText(range.index, `{{${key}}}`); // Insert the key at cursor
        addDynamicKeys((prev) => [...prev, key]);
      }
    }
  };

  const visibleAddresses = showMoreToAddress
    ? props.toAddress
    : [props.toAddress[0]];

  useEffect(() => {
    setHeight();
    window.addEventListener("resize", setHeight);
    return () => window.removeEventListener("resize", setHeight);
  }, [
    document.getElementById("composeMailAddress")?.offsetHeight,
    document.getElementById("mailSendButton")?.offsetHeight,
    showMoreToAddress,
    ccMembers,
    bccMembers,
    addCC,
    addBcc,
    loading,
    uploadedFiles,
  ]);

  const sendMail = () => {
    sentMailAnyway(selectedDynamicKeys, ccMembers, bccMembers, subject, mailBody, mailTextBody, uploadedAttchments);
  };

  const menu = (
    <Menu>
      {dynamicKeys.map((tag) => (
        <Menu.Item key={tag}>
          <DynamicMailTag
            key={tag}
            handleInsertValue={handleInsertValue}
            item={tag}
            selectedDynamicKeys={selectedDynamicKeys}
            records={props.record_id}
          />
        </Menu.Item>
      ))}
    </Menu>
  );


  // after click on create a with AI button function will call and open chatBox to give additional info to the ai api
  const handleChatBox = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault();
    setShowChatBox(!chatBox);
  };

  const handleMemberChange = (value: any) => {
    setCcMember(value);
  };

  const handleBccMemberChange = (value: any) => {
    setBccMember(value);
  };

  const discardMail = () => {
    setCC(false);
    setBcc(false);
    props.close();
  };

  const GetJobDescription = (description: string) => {
    setMailBody(description);
  };


  return (
    <div
      className={`flex flex-col sm:flex-row h-full w-full overflow-auto `}
      style={{ height: "600px", zIndex: 1 }}
    >
      <>
        {/* <div className="bottom-0 absolute rounded border right-0  mr-3 mb-2 shadow-lbr flex max-h-full w-128"> */}
        <div
          className={`flex ${
            isEditable ? "bg-gray-100" : "bg-white"
          } flex-col w-full rounded overflow-hidden`}
          id="composeMailbox"
          ref={props.refer}
          style={{ height: "600px" }}
        >
          <div
            id="mailheader"
            className={`flex justify-between  text-xs  items-center ${
              props.record_id.length == 1
                ? "text-black bg-opacity-20"
                : "text-tacitbase-secondary3 "
            } p-1 pl-4 pr-3 rounded-t flex`}
            style={{
              backgroundColor: "#F0EFFE",
              boxShadow:
                "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
            }}
          >
            {props.toAddress.length == 1 ? (
              <div className="flex space-x-1 items-center">
                <div className="flex-shrink-0">
                  {capitalizeFirstLetter("New message")}
                </div>
                <div className="truncate w-80">
                  <span className="mr-1">{props.record_id[0].first_name}</span>
                  <span>{props.record_id[0].last_name}</span>
                </div>
              </div>
            ) : (
              capitalizeFirstLetter(
                `You are in Bulk mode (${props.record_id.length})`
              )
            )}
            <Tooltip title="Discard mail">
                <Button
                  onClick={discardMail}
                  type="text"
                  icon={ <CloseOutlined style={{ color: "#4B48A5" }} />}
                  size="small"
                ></Button>
              </Tooltip>
          </div>
          <div className="h-full p-2" id="composeMailBody">
            <div id="composeMailAddress">
              <div className="flex space-x-2 items-start justify-start px-2 text-xs overflow-hidden ">
                <div className="w-12 flex justify-start items-center pt-1.5 ">To:</div>
                <div className={` items-end font-medium w-full `}>
                  <div className="flex max-w-full space-y-1 items-center flex-wrap  max-h-40 overflow-y-scroll">
                    {visibleAddresses &&
                      visibleAddresses.map(
                        (items) =>
                          items && (
                            <Tag
                              style={{
                                display: "flex",
                                alignItems: "center",
                                padding: "0px 2px",
                                margin: "2px 4px",
                                backgroundColor: "#F0EFFE",
                                border: "none",
                              }}
                            >
                              <Avatar
                                size={17}
                                style={{
                                  marginRight: 4,
                                  backgroundColor: "#4B48A5",
                                  color: "#D1D0FA",
                                  border: "1px",
                                  fontSize: "14px",
                                  textAlign: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {capitalizeFirstLetter(items.charAt(0))}
                              </Avatar>
                              {items}
                            </Tag>
                          )
                      )}
                    {props.toAddress.length > 1 && !showMoreToAddress && (
                      <button onClick={() => setShowMoreToAddress(true)}>
                        {props.toAddress.length - 1}
                        {"more"}
                      </button>
                    )}
                  </div>
                </div>
                {addBcc && addCC ? null : (
                  <div className="ml-14 flex pl-2 space-x-1 items-start text-sm justify-start text-tacitbase-secondary3">
                    {!addCC && (
                      <LabeledIconButton
                        data-testid="AddCC"
                        type="text"
                        label="Cc"
                        onClick={() => setCC(!addCC)}
                        className="hover:underline m-0 p-0"
                        size="small"
                        tooltipTitle="Add Cc recipients"
                        style={{ color: "#4B48A5" }}
                      />
                    )}
                    {!addBcc && (
                      <LabeledIconButton
                        data-testid="AddBcc"
                        type="text"
                        label="Bcc"
                        onClick={() => setBcc(!addBcc)}
                        className="hover:underline m-0 p-0"
                        size="small"
                        tooltipTitle="Add Bcc recipients"
                        style={{ color: "#4B48A5" }}
                      />
                    )}
                  </div>
                )}
              </div>
              {/* <Divider className="mt-0 mb-1"/> */}

              <div className="flex flex-col space-y-2">
                {addCC && (
                  <div className="w-full mt-2 ">
                    <div className="flex space-x-2 px-2 text-xs ">
                      <div className="w-12 flex justify-start items-center">
                        Cc:
                      </div>
                      <MemberPopup handleMemberChange={handleMemberChange} />
                    </div>
                  </div>
                )}
                {addBcc && (
                  <div className="w-full mt-2 ">
                    <div className="flex space-x-2 px-2 text-xs ">
                      <div className="w-12 flex justify-start items-center">
                        Bcc:
                      </div>
                      <MemberPopup handleMemberChange={handleBccMemberChange} />
                    </div>
                  </div>
                )}
              </div>
              <div className="flex space-x-2 px-2 mt-2  text-xs w-full ">
                {/* <div className="w-full flex flex-row items-center"> */}
                <div
                  className="flex justify-start items-center -mt-3 "
                  style={{ width: "46px" }}
                >
                  Subject:
                </div>
                <div className="w-full">
                  <SetSubject onchange={setSubject} />
                  {/* </div> */}
                </div>
                {orgMetadata?.ai_feature === "true" ? (
                  <ConfigProvider
                    theme={{
                      components: {
                        Button: {
                          colorPrimary: "#009D79",
                          colorPrimaryHover: "#009D79",
                          colorPrimaryActive: "#009D79",
                        },
                      },
                    }}
                  >
                    <Tooltip title="Generate with AI">
                    <Button
                      icon={<GPT />}
                      style={{
                        backgroundColor: validation(subject) ? "#d9d9d9" : "#009D79", // Set disabled background color
                        color: validation(subject) ? "#8c8c8c" : "#fff", // Set disabled text color
                        borderColor: validation(subject) ? "#d9d9d9" : "#009D79", // Set disabled border color
                      }}
                      disabled={validation(subject)}
                      onClick={handleChatBox}
                      size="middle"
                    />
                    </Tooltip>
                  </ConfigProvider>
                ) : (
                  <UpgradeButton
                    label="Generate with AI"
                    style={{
                      backgroundColor: "#009D79",
                      color: "#fff",
                      height: "32px",
                    }}
                    onClick={()=>handleBillingModal(setIsModalVisible)}
                  />
                )}
              </div>
            </div>
            <div className="mx-2" id="composeMailEditor">
              {loading ? (
                <div className="border rounded mb-2 animate-pulse">
                  <div className="flex-1 bg-gray-200 rounded ">
                    <div className="w-full h-8 bg-gray-300 mb-4 flex items-center  ">
                      <div className=" text-gray-600 animate-pulse ml-3 mr-12">
                        Normal
                      </div>
                      <div className="text-gray-600 mr-6 animate-pulse">
                        <BoldOutlined />
                      </div>
                      <div className="text-gray-600 mr-6 animate-pulse">
                        <ItalicOutlined />
                      </div>
                      <div className="text-gray-600 mr-6 animate-pulse">
                        <UnderlineOutlined />
                      </div>
                    </div>
                    <div className="composeMail px-4">
                      <Skeleton active />
                    </div>
                  </div>
                </div>
              ) : (
                <div className=" rounded  ">
                  <Editor
                    ref={quillRef}
                    value={mailBody}
                    onChange={handleChange}
                    modules={customModules}
                    formats={customFormats}
                    placeholder="Type your Mail description here..."
                    // style={{ minHeight: "80px", height: "300px" ,marginBottom:'8px'}}
                    className="composeMail"
                    onFocus={setEditorReady}
                  />
                </div>
              )}
            </div>

            {/* </div> */}
          </div>
          <div className="sticky bottom-2 px-2" id="mailSendButton">
            <div className="flex flex-wrap gap-2 mx-2 mt-2">
              {uploadedFiles.slice(0, 5).map((file: any, index) => (
                <UploadedFile
                  key={index}
                  name={file.name}
                  progress={file.progress}
                  onClose={() => {
                    const files = [...uploadedFiles];
                    files.splice(index, 1);
                    setUploadedFiles(files);
                  }}
                />
              ))}
              {uploadedFiles.length > 5 && (
                <div className="flex items-center justify-center text-gray-500">
                  +{uploadedFiles.length - 5} more
                </div>
              )}
            </div>
            <div className="justify-between w-full flex px-2 my-2 sticky">
              <Tooltip title="Discard mail">
                <Button
                  onClick={discardMail}
                  type="text"
                  icon={<DeleteOutlined style={{ color: "#4B48A5" }} />}
                ></Button>
              </Tooltip>
              <div className="flex items-center space-x-2">
                <Tooltip title="Add additional tag">
                  <Dropdown overlay={menu} trigger={["click"]}>
                    <Button
                      type="text"
                      icon={<FunctionOutlined style={{ color: "#4B48A5" }} />}
                      // onClick={}
                    />
                  </Dropdown>
                </Tooltip>
                {/* <Tooltip title="Open editor">
                  <Button
                    type="text"
                    icon={<FontSizeOutlined style={{ color: "#4B48A5" }} />}
                    // onClick={handleEditorClick}
                  />
                </Tooltip>
                <Tooltip title="Open editor">
                  <Button
                    type="text"
                    icon={<SmileOutlined style={{ color: "#4B48A5" }} />}
                  />
                </Tooltip> */}
                <div style={{ marginLeft: "8px" }}>
                  <input
                    type="file"
                    ref={fileInput}
                    style={{ display: "none" }}
                    onChange={(file: any) => handleFileUpload(file)}
                    multiple
                  />
                  <Tooltip title="Attach files">
                    <Button
                      className="items-center cursor-pointer focus:outline-none border-none"
                      onClick={() => fileInput.current?.click()}
                      data-testid="add-attachment"
                      type="text"
                      icon={<AttachmentIcon style={{ color: "#4B48A5" }} />}
                    />
                  </Tooltip>
                </div>
                <Button
                  disabled={validation(subject)}
                  type="primary"
                  // testId="sendMail"
                  onClick={sendMail}
                  icon={<SendOutlined />}
                  size="middle"
                  style={{ marginLeft: "16px" }}
                >
                  Send
                </Button>{" "}
              </div>
            </div>
          </div>
        </div>
        {chatBox && (
          <Chatbox
            handleChatBoxFromParent={handleChatBoxFromParent}
            getJobDescription={GetJobDescription}
            title={subject}
            handleLoading={handleLoading}
            getDisableState={props.getDisableState || (() => {})}
          />
        )}
        <UpgradePlanModal
        isModalVisible={isModalVisible}
        handleCancel={() => handleBillingModalCancel(setIsModalVisible)} // Pass the cancel handler with setIsModalVisible
      />
      </>
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  members: state.opening.members,
  openingMembers: state.opening.openingMembers,
  currentApplicant: state.opening.currentApplicant,
  applicantMembers: state.opening.applicantMembers,
  applicationId: state.opening.applicationId,
  clientId: state.opening.clientId,
  orgRoles: state.opening.orgRoles,
  loginUser: state.opening.loginUser,
  candidateDB: state.opening.candidateDB,
  currentOrg: state.opening.currentOrg,
});
export default connect(mapStateToProps)(ComposeMail);
