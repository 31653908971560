import React from "react";

import { connect } from "react-redux";
import { activity, orgMember } from "../../../type/type"
import Activity from "./Activity";
import TacitbaseAvtar from "../../../ant/Avtar"
import { generateTooltipForMember } from "../../../utils/capitalizeFirstLetter"
import { renderCreatedAt } from "../../../utils/renderCreatedAt";
type ShowCardACtivityProps = {
  activity: activity;
  members: orgMember;
  sources:{[key:string]:{name:string,strategy_name:string}};
};
const ShowCardActivity = (props: ShowCardACtivityProps) => {
  return (
    <>
      <div className="flex items-start mb-4 space-x-2">
        <div className="flex flex-shrink-0">
          {props.members[props.activity.owner_id] ? props.members[props.activity.owner_id].image_url ? (
          <TacitbaseAvtar size="default" toolTip={generateTooltipForMember(props.members[props.activity.owner_id])} src={props.members[props.activity.owner_id].image_url} />
          ) : <TacitbaseAvtar size="default" toolTip={generateTooltipForMember(props.members[props.activity.owner_id])} content={props.members[props.activity.owner_id].first_name[0] + props.members[props.activity.owner_id].last_name[0]} />
            :( props.activity.trackable_name ?(<TacitbaseAvtar size="default" toolTip={props.activity.trackable_name} content={props.activity.trackable_name[0] + props.activity.trackable_name[1]} />): null)}</div>
        <div className="break-words max-w-full text-sm mx-1 flex-col flex items-start justify-start">
          <div className="leading-normal ">
            {props.members[props.activity.owner_id] ? (
              <>
                <span className="font-semibold inline-block space-x-1  cursor-default tracking-wide mr-1 capitalize">
                  <span>
                    {props.members[props.activity.owner_id].first_name}
                  </span>
                  <span>
                    {props.members[props.activity.owner_id].last_name}
                  </span>
                </span>
              </>
            ) : <>
            <span className="font-semibold inline-block space-x-1  cursor-default tracking-wide mr-1 capitalize">
              <span>
                {props.activity.trackable_name}
              </span>
            </span>
          </>}
            <Activity activity={props.activity} sources = {props.sources} />
          </div>
          <div className="text-sm leading-none   break-words ">
            <span className="text-xs font-medium mr-1 text-gray-500">
              {renderCreatedAt(props.activity.created_at)}
            </span>
          </div>
        {props.activity.sub_trackable === "attachment" &&
        props.activity.action === "added" &&
        props.activity.sub_trackable_name.split(".")[
          props.activity.sub_trackable_name.split(".").length - 1
        ] !== "pdf" ? (
          props.activity.new_val.indexOf("https") >= 0 ||
          props.activity.new_val.indexOf("http") >= 0 ? (
            <embed
              src={props.activity.new_val}
              className="h-40 object-scale-down max-w-full"
            />
          ) : (
            <img src="" alt={props.activity.new_val} className="text-sm" />
          )
          ) : null}
        </div>

      </div>
    </>
  );
};

const mapStateToProps = (state: any) => ({
  loginUser: state.opening.loginUser,
  members: state.opening.members,
  sources: state.opening.candidateSources,
});

export default connect(mapStateToProps)(ShowCardActivity);
