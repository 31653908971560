import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { ESignDocument } from "../../../type/type";
import {
  CancelDocumentProcessWithReason,
  ConfirmableButton,
  DownloadDocument,
  ExtendDocumentProcess,
  ExtendZohoDocumentProcess,
  RemindParticipants,
  ResendDocumentForSign,
} from "./DocumentActionPopups";
import { CloseCircleOutlined } from "@ant-design/icons";
import { cancelDocumentSignatureProcess } from "../../../lib/api";
import { updateEsignDetails } from "../../../redux/actions/opening/action";
import  { throwSuccess } from "../../../service/throwError";

type GetDocumentActionByToolProps = {
  doc: ESignDocument;
  tracker_id?:string;
  applicant_id?:string;
  handleMessage?:(e:MessageEvent)=>void;
};

const GetDocumentActionByTool = ({ doc,tracker_id,applicant_id ,handleMessage}: GetDocumentActionByToolProps) => {
  const eSignDetails = useSelector((state: any) => state.opening.eSignDetails);
  const dispatch = useDispatch();
  const tool = eSignDetails?.tools[0];

  const getCancelActionByTool = (tool: string) => {
    switch (tool) {
      case "zohosign":
        return "recalled";
      case "signeasy":
        return "canceled";
      case "docusign":
        return "voided";
      default:
        return "canceled";
    }
  };
  const handleCancelDocumentProcess = async (id: string) => {
    try {
      const documentIndex = eSignDetails.documents.findIndex(
        (item: any) => item.id === id
      );

      if (documentIndex === -1) {
        throw new Error("Document not found");
      }

      const updatedDocuments = [...eSignDetails.documents];
      updatedDocuments[documentIndex] = {
        ...updatedDocuments[documentIndex],
        status: getCancelActionByTool(tool),
      };

      const response = await cancelDocumentSignatureProcess(id);
      dispatch(updateEsignDetails({ documents: updatedDocuments }));
      throwSuccess(response.message);
    } catch (error) {
     console.log(error)
    }
  };

  const renderActions = () => {
    if (doc.status === "complete" || doc.status === "completed") {
      return <DownloadDocument id={doc.id} key="download" />;
    }
    if (doc.status === "draft") {
      return <ResendDocumentForSign handleMessage = {handleMessage} doc_id={doc.id} tracker_id={tracker_id} applicant_id={applicant_id} key="download" />;
    }
  
    switch (tool) {
      case "signeasy":
        return [
          <RemindParticipants id={doc.id} key="remind" />,
          <ConfirmableButton
            key="cancel"
            title="Cancel document signature process"
            onConfirm={() => handleCancelDocumentProcess(doc.id)}
            description="Are you sure you want to cancel the document signature process?"
            buttonText="Cancel"
            icon={<CloseCircleOutlined />}
          />,
        ];
      case "docusign":
        return [
          <ExtendDocumentProcess id={doc.id} key="extend" />,
          <CancelDocumentProcessWithReason id={doc.id} key="cancelReason" />,
        ];
      case "zohosign":
        return [
          <RemindParticipants id={doc.id} key="remind" />,
          <ExtendZohoDocumentProcess id={doc.id} key="extend" />,
          <ConfirmableButton
            key="cancel"
            title="Cancel document signature process"
            onConfirm={() => handleCancelDocumentProcess(doc.id)}
            description="Are you sure you want to cancel the document signature process?"
            buttonText="Cancel"
            icon={<CloseCircleOutlined />}
          />
          ];
      default:
        return null;
    }
  };
  

  return <div className="flex flex-col">{renderActions()}</div>;
};

export default GetDocumentActionByTool;
