import { AGENCIES, CDBMember, OTHER, REFERRAL, THIRD_PARTY } from "../../constant";
import { Source } from "../../lib/apiRes"
import {
  Attachment,
  Candidate,
  commentTagType,
  InitLabel,
  Label,
  Member,
  openingMemberType,
} from "../../type/type.d";
import {
  reviewAttachments, currentCDBactivity,
  candidateDB,
} from "./initialState";
export function add_Candidate_In_DB(state: any, action: any) {
  let key: string[] = Object.keys(state.candidateDB);
  let objKey = key.length > 0 ? key[0] : 1;
  return {
    ...state,
    candidateDB: {
      ...state.candidateDB,
      [objKey]: {
        [action.data.id]: action.data,
        ...state.candidateDB[objKey],
      },
    },
  };
}
export function add_CandidateDB_Table_header(state: any, action: any) {
  return {
    ...state,
    candidateDBTableHeader: [...state.candidateDBTableHeader, action.data],
  };
}
export function init_candidate_in_review(state: any, action: any) {
  var candidatesInReview: any = {};

  if (action.data.length > 0) {
    action.data.map((candidate: any) => {
      candidate.labels = candidate.labels ? candidate.labels.map((label: Label) => label.id) : [];
      candidate.members = candidate.members ? candidate.members : [];
      candidatesInReview[candidate.id] = candidate;
    });
  }

  const currentPage = action.currentPage;  // Assume `currentPage` is passed in the action
  const isSearchResult = action.isSearchResult || false;

  return {
    ...state,
    candidatesInReview: action.reviewTag === state.candidatesInReview.reviewed
      ? {
        ...state.candidatesInReview,
        currentPage: action.currentPage,
        reviews: isSearchResult 
          ? { [currentPage]: candidatesInReview }  // Reset reviews for search results
          : {
              ...state.candidatesInReview.reviews,
              [currentPage]: {
                ...state.candidatesInReview.reviews[action.currentPage],
                ...candidatesInReview,
              },
            },
      }
      : {
        currentPage: action.currentPage,
        reviewed: action.reviewTag,
        reviews: {
          [currentPage]: candidatesInReview,
        },
      },
    candidateDB: candidateDB,
  };
}

export function add_candidate_to_review(state: any, action: any) {
  var candidateReview: any = {};
  if (action.reviewTag === state.candidatesInReview.reviewed) {
    action.data.length > 0
      ? action.data.map((candidate: any) => {
        if (candidate.reviewed)
          candidate.labels = candidate.labels
            ? candidate.labels.map((label: Label) => label.id)
            : [];
        candidate.members = candidate.members ? candidate.members : [];
        candidateReview[candidate.id] = candidate;
      })
      : (candidateReview = state.candidatesInReview);
  }


  return {
    ...state,
    candidatesInReview:
      action.reviewTag === state.candidatesInReview.reviewed
        ? {
          ...state.candidatesInReview,
          reviews: {
            ...state.candidatesInReview.reviews,
            ...candidateReview,
          },
        }
        : state.candidatesInReview,
  };
}
export function init_current_candidate_in_review(state: any, action: any) {
  return {
    ...state,
    currentCandidateInReview: action.data,
    reviewAttachments: reviewAttachments,
  };
}

export function init_candidate_labels(state: any, action: any) {
  let candidateLabels: any = {};
  action.data.map((label: Label) => {
    candidateLabels[label.id] = label;
  });
  return {
    ...state,
    candidateLabels: candidateLabels,
  };
}
export function update_candidate_labels(state: any, action: any) {
  return {
    ...state,
    candidateLabels: {
      ...state.candidateLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_candidate_labels(state: any, action: any) {
  return {
    ...state,
    candidateLabels: {
      ...state.candidateLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_member_on_CDB(state: any, action: any) {
  var CDBMembers: openingMemberType[] = [];
  action.data.map((member: Member) => {
    CDBMembers.push({
      id: member.id,
      access: member.access,
    });
  });
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      [CDBMember]: state.servicesMembership[CDBMember]
        ? [...state.servicesMembership[CDBMember], ...CDBMembers]
        : CDBMembers,
    },
  };
}

export function init_CDB_member(state: any, action: any) {
  var CDBMembers: any[] = [];
  action.data.map((member: Member) => {
    CDBMembers.push({ id: member.id, access: member.access });
  });
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      [CDBMember]: CDBMembers,
    },
  };
}
export function init_CDBtable_head(state: any, action: any) {
  return {
    ...state,
    candidateDBTableHeader: action.data,
  };
}

export function init_candidates(state: any, action: any) {
  let candidates: any = {};
  action.data.map((candidate: Candidate) => {
    if (candidate)
      candidates[candidate.id] = candidate;
  });
  const updatedCandidatesWithoutPage = {
    ...state.candidateDBWithoutPage,
    candidates,
  };
  return {
    ...state,
    candidateDB:
      Object.keys(state.candidateDB).length < 3
        ? { ...state.candidateDB, [action.currentpage]: candidates, currentpage: action.currentpage }
        : { [action.currentpage]: candidates, currentpage: action.currentpage },
    candidateDBWithoutPage: updatedCandidatesWithoutPage,
    reviewComments: {},
    reviewAttachments: {},
  };
}

export function edit_candidate(state: any, action: any) {
  return {
    ...state,
    candidateDB: {
      ...state.candidateDB,
      [action.currentPage]: {
        ...state.candidateDB[action.currentPage],
        [action.data.id]: action.data,
      },
    },
  };
}

export function init_review_attachments(
  state: any,
  action: {
    data: Attachment[];
    reviewId: string;
  }
) {
  return {
    ...state,
    reviewAttachments: {
      [action.reviewId]: action.data,
    },
  };
}
export function init_candidate_attachments(
  state: any,
  action: {
    data: Attachment[];
  }
) {
  let attachments: { [key: string]: Attachment } = {};
  action.data.length > 0
    ? action.data.map(
      (attchment: Attachment) => (attachments[attchment.id] = attchment)
    )
    : attachments;
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      attachments: attachments,
    },
  };
}

export function add_CDB_attachment_upload_status(state: any, action: any) {
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      newAttachmentUploader: action.data,
    },
  };
}

export function init_review_comments(
  state: any,
  action: {
    data: any;
    reviewId: string;
    tag: string;
  }
) {
  let reviewComment: any = {};
  action.data.map((a: any) => {
    a["tag"] = action.tag;
    reviewComment[a.id] = a;
  });
  return {
    ...state,
    reviewComments: {
      [action.reviewId]: {
        ...state.reviewComments[action.reviewId],
        ...reviewComment,
      },
    },
  };
}

export function add_review_comment(
  state: any,
  action: {
    data: commentTagType;
    tag: string;
  }
) {
  action.data["tag"] = action.tag;

  // Create a copy of the candidatesInReview.reviews to avoid direct mutation
  const updatedCandidateDB = { ...state.candidatesInReview.reviews };

  // Iterate through each page to find the review and update the comment count
  Object.keys(updatedCandidateDB).forEach((pageKey) => {
    const candidatesOnPage = updatedCandidateDB[pageKey];

    if (candidatesOnPage[action.data.review_id]) {
      updatedCandidateDB[pageKey][action.data.review_id] = {
        ...candidatesOnPage[action.data.review_id],
        comment_count: candidatesOnPage[action.data.review_id].comment_count + 1,
      };
    }
  });

  return {
    ...state,
    reviewComments: state.currentCandidateInReview.id === action.data.review_id
      ? {
        ...state.reviewComments,
        [action.data.review_id]: {
          ...state.reviewComments[action.data.review_id],
          [action.data.id]: action.data,
        },
      }
      : state.reviewComments,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateDB,
    },
  };
}


export function init_current_candidate_labels(state: any, action: any) {
  var label: string[] = [];
  action.data.map((member: Member) => {
    label.push(member.id);
  });
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      labels: label,
    },
    currentCDBactivity: currentCDBactivity,
    currentCDBAtsActivity: currentCDBactivity,
  };
}
export function init_current_candidate_in_db(state: any, action: any) {
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      candidateId: action.candidateId,
    },
    currentCDBactivity: currentCDBactivity,
    currentCDBAtsActivity: currentCDBactivity,
  };
}
export function add_label_current_candidate_in_db(state: any, action: any) {
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      labels: [...state.currentCandidateInEditMode.labels, action.data.id],
    },
    candidateLabels: {
      ...state.candidateLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_labels_to_candidate_in_review(state: any, action: any) {
  // Create a copy of the candidatesInReview.reviews to avoid direct mutation
  const updatedCandidateReview = { ...state.candidatesInReview.reviews };

  // Iterate over each item in action.data

  // Iterate through each page in the updatedCandidateReview
  Object.keys(updatedCandidateReview).forEach(pageKey => {
    const candidatesOnPage = updatedCandidateReview[pageKey];

    // Check if the candidate exists on the current page
    if (candidatesOnPage[action.reviewId]) {
      candidatesOnPage[action.reviewId] = {
        ...candidatesOnPage[action.reviewId],
        // Merge existing labels with new ones and remove duplicates
        labels: [...new Set([...candidatesOnPage[action.reviewId].labels, ...action.data.label])],
      };
    }
  });

  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateReview
    },
    reviewLabels: {
      ...state.reviewLabels,
      [action.data.label.id]: action.data.label,
    },
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      labels: [...state.currentCandidateInReview.labels, action.data.label],
    },
  };
}

export function add_review_attachment(
  state: any,
  action: {
    data: Attachment[];
    reviewId: string;
  }
) {
  const updatedCandidateDB = { ...state.candidatesInReview.reviews };

  // Iterate through each page to find the review and update the attachment count
  Object.keys(updatedCandidateDB).forEach((pageKey) => {
    const candidatesOnPage = updatedCandidateDB[pageKey];

    if (candidatesOnPage[action.reviewId]) {
      updatedCandidateDB[pageKey][action.reviewId] = {
        ...candidatesOnPage[action.reviewId],
        attachment_count:
          candidatesOnPage[action.reviewId].attachment_count + action.data.length,
      };
    }
  });

  return {
    ...state,
    reviewAttachments: {
      ...state.reviewAttachments,
      [action.reviewId]: state.reviewAttachments[action.reviewId]
        ? state.reviewAttachments[action.reviewId].concat(...action.data)
        : [...action.data],
    },
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateDB,
    },
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      attachment_count: state.currentCandidateInReview.attachment_count + action.data.length,
    },
  };
}


export function add_candidate_attachment(
  state: any,
  action: {
    data: Attachment[];
  }
) {
  let attachments: { [key: string]: Attachment } = {};
  action.data.length > 0
    ? action.data.map(
      (attchment: Attachment) => (attachments[attchment.id] = attchment)
    )
    : attachments;
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      attachments: {
        ...state.currentCandidateInEditMode.attachments,
        ...attachments,
      },
    },
  };
}
export function update_attachment_of_candidate(state: any, action: any) {
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      attachments: {
        ...state.currentCandidateInEditMode.attachments,
        [action.data.id]: {
          ...state.currentCandidateInEditMode.attachments[action.data.id],
          name: action.data.name,
        },
      },
    },
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      attachments:state.currentCandidateInPool.attachments && state.currentCandidateInPool.attachments.map((attachment:Attachment)=>attachment.id===action.data.id?{...attachment,["name"]:action.data.name}:attachment)
    },

  };
}
export function delete_attachment_of_candidate(state: any, action: any) {
  let candidateAttchments: any = {};
  let copiedattachment = Object.values(
    state.currentCandidateInEditMode.attachments
  ).filter((attchment: any) => attchment.id !== action.data);
  copiedattachment.map((attchment: any) => {
    candidateAttchments[attchment.id] = attchment;
  });
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      attachments: candidateAttchments,
    },
    currentCandidateInPool: {
      ...state.currentCandidateInPool,
      attachments: state.currentCandidateInPool.attachments && state.currentCandidateInPool.attachments.filter((attachment: Attachment) => attachment.id !== action.data),
    },
  };
}

export function init_review_labels(state: any, action: any) {
  let labels: InitLabel = {};
  action.data.map((label: Label) => {
    labels[label.id] = label;
  });
  return {
    ...state,
    reviewLabels: labels,
  };
}
export function update_review_labels(state: any, action: any) {
  return {
    ...state,
    reviewLabels: {
      ...state.reviewLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_reviews_labels(state: any, action: any) {
  return {
    ...state,
    reviewLabels: {
      ...state.reviewLabels,
      [action.data.id]: action.data,
    },
  };
}

export function add_member_on_review(state: any, action: any) {

  // Create a copy of the candidatesInReview.reviews to avoid direct mutation
  const updatedCandidateReview = { ...state.candidatesInReview.reviews };

  // Iterate through each page in the updatedCandidateReview
  Object.keys(updatedCandidateReview).forEach(pageKey => {
    const candidatesOnPage = updatedCandidateReview[pageKey];

    // Check if the candidate exists on the current page
    if (candidatesOnPage[action.reviewId]) {
      candidatesOnPage[action.reviewId] = {
        ...candidatesOnPage[action.reviewId],
        // Merge existing labels with new ones and remove duplicates
        members: [...new Set([...candidatesOnPage[action.reviewId].members, ...action.data.map((member: Member) => member.id)])],
      };
    }
  });
  return {
    ...state,
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      members: [...state.currentCandidateInReview.members, ...action.data.map((member: Member) => member.id)],
    },
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateReview
    },
  };
}

export function remove_member_on_review(state: any, action: any) {
  // Create a copy of the candidatesInReview.reviews to avoid direct mutation
  const updatedCandidateReview = { ...state.candidatesInReview.reviews };
  // Iterate through each page in the updatedCandidateReview
  Object.keys(updatedCandidateReview).forEach(pageKey => {
    const candidatesOnPage = updatedCandidateReview[pageKey];

    // Check if the candidate exists on the current page
    if (candidatesOnPage[action.reviewId]) {
      candidatesOnPage[action.reviewId] = {
        ...candidatesOnPage[action.reviewId],
        // Merge existing labels with new ones and remove duplicates
        members: candidatesOnPage[action.reviewId].members.filter(
          (member: string) => member !== action.data[0].id
        ),
      };
    }
  });
  return {
    ...state,
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      members: state.currentCandidateInReview.members.filter(
        (member: string) => member !== action.data[0].id
      ),
    },
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateReview
    },
  };
}

export function update_cdb_member_role(state: any, action: any) {
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      CDBMember: state.servicesMembership.CDBMember
        ? state.servicesMembership.CDBMember.map((m: any) =>
          m.id == action.memberId
            ? {
              ...m,
              access: action.access,
            }
            : m
        )
        : [],
    },
  };
}

export function delete_cdb_member(state: any, action: any) {
  return {
    ...state,
    servicesMembership: {
      ...state.servicesMembership,
      CDBMember: state.servicesMembership.CDBMember
        ? state.servicesMembership.CDBMember.filter(
          (m: any) => m.id != action.memberId
        )
        : [],
    },
  };
}

export function add_labels_on_multiple_review(state: any, action: any) {
  // Create a copy of the candidateDB to avoid direct mutation
  const updatedCandidateDB = { ...state.candidatesInReview.reviews };

  // Iterate over each item in action.data
  action.data.forEach((item: { id: string, labels: any[] }) => {
    let { id, labels } = item;
    // Iterate through each page in the candidateDB
    Object.keys(updatedCandidateDB).forEach(pageKey => {
      const candidatesOnPage = updatedCandidateDB[pageKey];

      // Check if the candidate exists on the current page
      if (candidatesOnPage[id]) {
        updatedCandidateDB[pageKey][id] = {
          ...candidatesOnPage[id],
          labels: [...new Set([...candidatesOnPage[id].labels, ...labels.map((label) => label.id)])]
        };
      }
    });
  });
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateDB
    },
  };
}

export function add_members_on_multiple_review(state: any, action: any) {
  // Create a copy of the candidateDB to avoid direct mutation
  const updatedCandidateReviews = { ...state.candidatesInReview.reviews };

  // Iterate over each item in action.data
  action.data.forEach((item: { id: string, members: any[] }) => {
    let { id, members } = item;
    // Iterate through each page in the candidateDB
    Object.keys(updatedCandidateReviews).forEach(pageKey => {
      const candidatesOnPage = updatedCandidateReviews[pageKey];

      // Check if the candidate exists on the current page
      if (candidatesOnPage[id]) {
        updatedCandidateReviews[pageKey][id] = {
          ...candidatesOnPage[id],
          members: [...new Set([...candidatesOnPage[id].members, ...members.map((member) => member.id)])]
        };
      }
    });
  });
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateReviews
    },
  };
}
export function update_comment_of_review(state: any, action: any) {
  return {
    ...state,
    reviewComments: state.reviewComments[action.data.review_id] ? {
      ...state.reviewComments,
      [action.data.review_id]: {
        ...state.reviewComments[action.data.review_id],
        [action.data.id]: {
          ...state.reviewComments[action.data.review_id][action.data.id],
          body: action.data.body,
        },
      },
    } : state.reviewComments,
  };
}
export function update_state_of_close_review(state: any, action: any) {
  // Create a copy of the candidateDB to avoid direct mutation
  const updatedCandidateReviews = { ...state.candidatesInReview.reviews };
  // Iterate through each page in the candidateDB
  Object.keys(updatedCandidateReviews).forEach(pageKey => {
    const candidatesOnPage = updatedCandidateReviews[pageKey];

    // Check if the candidate exists on the current page
    if (candidatesOnPage[action.reviewId]) {
      updatedCandidateReviews[pageKey][action.reviewId] = {
        ...candidatesOnPage[action.reviewId],
        reviewed: !candidatesOnPage[action.reviewId].reviewed,
      };
    }
  });
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateReviews
    },
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      reviewed: true
    }
  };
}
export function current_CDB_activity(state: any, action: any) {
  let reviewComment: any = {};
  action.comments.map((a: any) => {
    a["tag"] = action.tag;
    reviewComment[a.id] = a;
  });

  return {
    ...state,
    currentCDBactivity: {
      ...action.review,
      ["comments"]: action.review.id == state.currentCDBactivity.id ? {
        ...state.currentCDBactivity["comments"],
        ...reviewComment,
      } : { ...reviewComment },
    },
  };
}
export function current_CDB_review_activity(state: any, action: any) {
  let reviewComment: any = {};
  action.comments.map((a: any) => {
    a["tag"] = action.tag;
    reviewComment[a.id] = a;
  });

  return {
    ...state,
    currentCDBactivity: {
      ...state.currentCDBactivity,
      ["comments"]: {
        ...state.currentCDBactivity["comments"],
        ...reviewComment,
      },
    },
  };
}
export function current_CDB_ATS_activity(state: any, action: any) {
  return {
    ...state,
    currentCDBAtsActivity: {
      [action.candidateApplicants.id]: action.candidateApplicants,
    },
  };
}
export function remove_label_from_review(state: any, action: { reviewId: string, labels: string[] }) {
  // Create a copy of the candidatesInReview.reviews to avoid direct mutation
  const updatedCandidateDB = { ...state.candidatesInReview.reviews };

  // Iterate through each page in the updatedCandidateDB
  Object.keys(updatedCandidateDB).forEach(pageKey => {
    const candidatesOnPage = updatedCandidateDB[pageKey];

    // Check if the candidate exists on the current page
    if (candidatesOnPage[action.reviewId]) {
      // Update the labels by filtering out the ones specified in action.labels
      updatedCandidateDB[pageKey][action.reviewId] = {
        ...candidatesOnPage[action.reviewId],
        labels: candidatesOnPage[action.reviewId].labels
          ? candidatesOnPage[action.reviewId].labels.filter(
            (label: string) => !action.labels.includes(label)
          )
          : [],
      };
    }
  });
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateDB
    },
    currentCandidateInReview: {
      ...state.currentCandidateInReview,
      labels: state.currentCandidateInReview.labels
        ? state.currentCandidateInReview.labels.filter(
          (label: Label) => label.id !== action.labels[0]
        )
        : [],
    },

  };
}
export function remove_label_from_reviews(state: any, action: any) {
  const updatedCandidateReviews = { ...state.candidatesInReview.reviews };

  // Iterate over each item in action.data
  action.data.forEach((item: string) => {
    Object.keys(updatedCandidateReviews).forEach(pageKey => {
      const candidatesOnPage = updatedCandidateReviews[pageKey];

      // Check if the candidate exists on the current page
      if (candidatesOnPage[item]) {
        updatedCandidateReviews[pageKey][item] = {
          ...candidatesOnPage[item],
          labels: candidatesOnPage[item].labels.filter((labelId: string) =>
            !action.labels.includes(labelId)
          )
        };
      }
    });
  });
  return {
    ...state,
    candidatesInReview: {
      ...state.candidatesInReview,
      reviews: updatedCandidateReviews
    },
  };
}

export function remove_label_from_candidate(state: any, action: any) {
  return {
    ...state,
    currentCandidateInEditMode: {
      ...state.currentCandidateInEditMode,
      labels: state.currentCandidateInEditMode.labels
        ? state.currentCandidateInEditMode.labels.filter(
          (label: string) => !action.labels.includes(label)
        )
        : [],
    },
  };
}

// plugin

export function init_mappings(state: any, action: any) {
  var mappings: any = {};

  action.data.map((mapping: any) => {
    mappings[mapping.id] = mapping;
  });
  return {
    ...state,
    pluginData: { ...state.pluginData, mappings: mappings },
  };
}

export function update_mapping(state: any, action: any) {
  return {
    ...state,
    pluginData: {
      ...state.pluginData,
      mappings: {
        ...state.pluginData.mappings,
        [action.data.map_id]: action.data.value,
      },
    },
  };
}

export function init_sources(state: any, action: any) {
  let sources: { [key: string]: { name: string, profile: string, strategy_name?:string } } = {}
  action.payload.map((source: Source) => {
    switch (source.strategy_name) {
      case REFERRAL:
        sources[source.tb_src] = {
          "name": state.loginUser.user.id == source.name ? state.members[source.name].first_name + " " + state.members[source.name].last_name + " (You)" : state.members[source.name].first_name + " " + state.members[source.name].last_name,
          "profile": state.members[source.name].image_url,
          "strategy_name":source.strategy_name
        }
        break
      case THIRD_PARTY:
        sources[source.tb_src] = {
           "name": source.name,
           "profile":source.link
         }
        break
      case AGENCIES:
      case OTHER:
        sources[source.tb_src] = {
          "name": source.name,
          "profile": state.applicationImgUrl
        };
        break
    }
  })
  return {
    ...state,
    candidateSources: sources,
  }
}


export function applicant_status(state: any, action: any) {
  let applicants: { [key: string]: string[] } = {}
  action.payload.map((applicant: { id: string, trackers: string[] }) => {
    applicants[applicant.id] = applicant.trackers
  })
  return {
    ...state,
    applicantStatus: applicants
  }
}

export function add_labels_on_multiple_candidates_in_talentpool(state: any, action: any) {
  // Create a copy of the candidateDB to avoid direct mutation
  const updatedCandidateDB = { ...state.candidateDB };

  // Iterate over each item in action.data
  action.data.forEach((item: { id: string, labels: any[] }) => {
    let { id, labels } = item;
    if (!id || !Array.isArray(labels)) {
      return;
    }
    // Iterate through each page in the candidateDB
    Object.keys(updatedCandidateDB).forEach(pageKey => {
      const candidatesOnPage = updatedCandidateDB[pageKey];

      // Check if the candidate exists on the current page
      if (candidatesOnPage[id] && candidatesOnPage[id]) {
        updatedCandidateDB[pageKey][id] = {
          ...candidatesOnPage[id],
          labels: [...new Set([...(candidatesOnPage[id].labels||[]), ...labels])]
        };
      }
    });
  });

  return {
    ...state,
    candidateDB: updatedCandidateDB,
  };
}

export function remove_labels_from_multiple_candidates_in_talentpool(state: any, action: any) {
  // Create a copy of the candidateDB to avoid direct mutation
  const updatedCandidateDB = { ...state.candidateDB };

  // Iterate over each candidate ID
  action.data.forEach((candidateId: string) => {
    // Iterate through each page in the candidateDB
    Object.keys(updatedCandidateDB).forEach(pageKey => {
      const candidatesOnPage = updatedCandidateDB[pageKey];
      // Check if the candidate exists on the current page
      if (candidatesOnPage[candidateId]) {
        // Filter out the labels specified in action.data.labels
        const updatedLabels = candidatesOnPage[candidateId].labels.filter((labelId: Label) =>
          !action.labels.includes(labelId.id)
        );
        // Update the candidate's labels
        updatedCandidateDB[pageKey][candidateId] = {
          ...candidatesOnPage[candidateId],
          labels: updatedLabels
        };
      }
    });
  });

  return {
    ...state,
    candidateDB: updatedCandidateDB,
  };
}
