import React from "react";
import { Button, Space, Tooltip, Typography } from "antd";
import { CheckCircleFilled, CloseOutlined, QuestionCircleFilled, QuestionCircleOutlined} from "@ant-design/icons";
import TacitbaseAvtar from "../../../ant/Avtar";
import { AttendeeWithImage } from "../../../type/type";

const { Text } = Typography;

interface GuestItemProps {
  guest: AttendeeWithImage;
  setAddedGuests: React.Dispatch<React.SetStateAction<AttendeeWithImage[]>>;
  removeGuest: (guest: AttendeeWithImage) => void;
}

const GuestItem: React.FC<GuestItemProps> = ({ guest, setAddedGuests, removeGuest }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
      }}
      className="gap-2"
    >
      <Space direction="horizontal" size="large" className="flex w-full justify-between">
        <Space direction="horizontal">
          <div
            style={{
              position: "relative",
              display: "inline-block",
            }}
          >
            {guest.image ? (
              <TacitbaseAvtar size="default" src={guest.image} />
            ) : (
              <TacitbaseAvtar size="default" content={guest?.email?.[0]} />
            )}
            {guest.responseStatus === "accepted" && (
              <CheckCircleFilled
                style={{
                  color: "green",
                  position: "absolute",
                  bottom: 0,
                  right: 0,
                  fontSize: 12,
                }}
              />
            )}
          </div>

          <div style={{ flexGrow: 1 }} className="leading-3">
            <Text
              style={{
                display: "block",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                maxWidth: "170px",
              }}
            >
              {guest.email}
            </Text>
            <div>
              <Text style={{ fontSize: 12 }} type="secondary">
                {guest.organizer && "Organizer"}
                {guest.optional && guest.organizer && ", "}
                {guest.optional && "Optional"}
              </Text>
            </div>
          </div>
        </Space>
        {!guest.is_candidate && (
          <div className="flex items-center gap-1">
            <Tooltip title="Mark as optional">
            <Button
              type="text"
              size="small"
              icon={guest.optional ? <QuestionCircleFilled /> : <QuestionCircleOutlined />}
              onClick={() => {
                setAddedGuests((prevGuests) =>
                  prevGuests.map((g) =>
                    g.email === guest.email ? { ...g, optional: !g.optional } : g
                  )
                );
              }}
            />
            </Tooltip>
            <Tooltip title="Remove the guest">
            <Button
              type="text"
              size="small"
              icon={<CloseOutlined />}
              onClick={() => removeGuest(guest)}
              style={{ marginLeft: "auto" }}
            />
            </Tooltip>
          </div>
        )}
      </Space>
    </div>
  );
};

export default GuestItem;
