import React from "react";
import { connect } from "react-redux";
import { updateSearchFlag } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { useHistory } from "react-router-dom";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import ReviewIcon from "../Icons/ReviewIcon";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd/lib";
import CommentIcon from "../Icons/CommentIcon";
import MailIcon from "../Icons/MailIcon";
import { convertUnixToISO } from "../../utils/ConvertUnixToIso";

interface ReviewsCardViewProps {
  applicationId: string;
  searchResult: any;
  updateSearchFlag(): void;
}
export const preprocessSnippet = (snippet: string): string => {
  // Replace closing `</mark>` tags followed immediately by an opening `<mark>` tag with a space between them.
  return snippet.replace(/<\/mark>(\s*)<mark>/g, "</mark>&nbsp;<mark>");
};

function ReviewsCardView({
  applicationId,
  searchResult,
}: // updateSearchFlag,
ReviewsCardViewProps) {
  const history = useHistory();

  const showCard = (applicant_id: string) => {
    history.push({
      pathname: `/o/${applicationId}/reviews/${applicant_id}`,
    });
  };

  const { document } = searchResult;

  // Fallback logic for name and description
  const nameSnippet = document.highlight.name
    ? document.highlight.name.snippet
    : document.name;
  //   const descriptionSnippet = highlight.description ? highlight.description.snippet : document.description;
  const date = convertUnixToISO(document.updated_at);

  return (
    <div
      className="flex flex-row rounded items-start p-2 gap-2"
      style={{ width: "100%" }}
    >
      <Tooltip title="Review">
      {/* <Avatar style={{ backgroundColor: "#4B48A5" }} icon={<ReviewIcon />} /> */}
      <ReviewIcon style={{fontSize:'20px'}} />
      </Tooltip>
      <div
        className="flex flex-col space-y-0.5 "
        onClick={() => showCard(document.id)}
        role="button"
        tabIndex={0}
        style={{ width: "95%" }}
        onKeyDown={() => showCard(document.id)}
        data-testid={document.id}
      >
        <div className="flex flex-row justify-between  text-xs font-medium w-full">
          <div
            className="flex leading-4 truncate text-xs tracking-normal font-medium pl-1 overflow-ellipsis"
            style={{ maxWidth: "60%" }}
            dangerouslySetInnerHTML={{ __html: preprocessSnippet(nameSnippet) }} // Render highlighted name or fallback
          />

          {renderCreatedAt(date.toString())}
        </div>
        <div className="flex flex-row justify-between items-center text-xs font-medium w-full">
            <div
              className="flex items-center truncate pl-1"
              style={{ maxWidth: "60%" }}
            >
              <MailIcon style={{ marginRight: "4px" }} />
              <span className="truncate">{document.candidates.email}</span>
            </div>

          <div
            className="flex flex-row items-center justify-end space-x-4"
            style={{ flexGrow: 1 }}
          >
            {/* Comment Count */}
            <Tooltip title={`${document.comment_count} comments`}>
              <div className="flex items-center">
                <CommentIcon style={{ color: "#1890ff", marginRight: "4px" }} />
                {document.comment_count}
              </div>
            </Tooltip>

            {/* Reviewed Status */}
            <Tooltip title={document.reviewed ? "Reviewed" : "Not Reviewed"}>
              {document.reviewed ? (
                <CheckCircleOutlined style={{ color: "#52c41a" }} />
              ) : (
                <ClockCircleOutlined  />
              )}
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  updateSearchFlag: () => dispatch(updateSearchFlag()),
});

export default connect(mapStateToProps, mapPropsToState)(ReviewsCardView);
