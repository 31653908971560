import React, { memo, useState } from "react";
import { Button, Checkbox, ConfigProvider, List, Popover} from "antd";
import { RootState } from "../../../redux/reducers/root";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { calendarListState, updateTeamCalendarStatus } from "../../../redux/reducers/calendarSlice";
import { CalendarItem } from "../../../lib/apiRes";
import { MoreOutlined, SettingOutlined, TeamOutlined } from "@ant-design/icons";
import CalendarSharingSettings from "./CalendarSettingsModal";
import TeamCalendarModal from "./CreateCalendar";
import { OWNER } from "../../../constant";
import { throwSuccess } from "../../../service/throwError";
import { markAsTeamCalendar } from "../../../lib/api";

interface CalendarListProps {
  onCalendarToggle: (calendar: CalendarItem,value:boolean) => void;
  selectedCalendars: string[];
}
const selectedUserRole = (state: RootState) => state.opening.roles;


const CalendarList: React.FC<CalendarListProps> = memo(
  ({ onCalendarToggle, selectedCalendars }) => {
    const selectedUserRoleName = useSelector(selectedUserRole, shallowEqual);

    CalendarList.displayName = "CalendarList";
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
    const [selectedCalendarForEdit, setSelectedCalendarForEdit] = useState<
      CalendarItem | undefined
    >(() => undefined);
    const [openPopoverId, setOpenPopoverId] = useState<string | null>(null);
    const [loadingMarkAsTeam, setLoadingMarkAsTeam] = useState(false);
    const dispatch = useDispatch();

   
    const calendarList: calendarListState = useSelector(
      (state: RootState) => state.calendar.calendarList
    );

    // Separate calendars into personal and team calendars
    const personalCalendars = Object.values(calendarList).filter(
      (calendar) => !calendar.is_team_calendar
    );
    const teamCalendars = Object.values(calendarList).filter(
      (calendar) => calendar.is_team_calendar
    );

    const handlePermissionsClick = (calendar: CalendarItem) => {
      setSelectedCalendarForEdit(calendar);
      setIsSettingsModalOpen(true);
      setOpenPopoverId(null);
    };

    const handleMarkAsTeamCalendar = async (calendar: CalendarItem) => {
      setLoadingMarkAsTeam(true);
      try {
        await markAsTeamCalendar({
          calendar_id: calendar.id,
          calendar_summary: calendar.summary,
        });
        dispatch(updateTeamCalendarStatus({
          oldTeamCalendarId: teamCalendars && teamCalendars[0]?.id || "",
          newTeamCalendarId: calendar.id,
        }));
        throwSuccess("Calendar marked as team calendar successfully");
      } catch (error) {
        // Handle error if needed
      } finally {
        setLoadingMarkAsTeam(false);
      }
      setOpenPopoverId(null);
    };

    const renderPopoverContent = (calendar: CalendarItem) => (
      <div className="py-1">
        <Button
          type="text"
          icon={<SettingOutlined />}
          className={`items-center w-full text-left hover:bg-gray-50  ${
            calendar.access_role === "owner" ? "flex left-align-button" : "hidden"
          }`}
          onClick={() => handlePermissionsClick(calendar)}
        >
          <span className="">Permissions</span>
        </Button>
        <Button
      type="text"
      icon={<TeamOutlined />}
      className={`items-center w-full text-left hover:bg-gray-50  ${
        calendar.access_role === "owner" && !calendar.is_team_calendar ? "flex left-align-button" : "hidden"
      }`}
      onClick={() => handleMarkAsTeamCalendar(calendar)}
      loading={loadingMarkAsTeam}
    >
      <span className="">Mark as team calendar</span>
    </Button>
      </div>
    );

    const renderItem = (calendar: CalendarItem) => {
     
      return (
        <List.Item
          key={calendar.id}
          className="hover:bg-gray-100 rounded px-1"
          style={{ padding: 6 }}
        >
          <div className="flex items-center justify-between w-full">
            <div className="flex items-center min-w-0 flex-1">
              <ConfigProvider
                theme={{
                  token: {
                    colorBgContainer: selectedCalendars.includes(calendar.id)
                      ? calendar.background_color
                      : "white",
                    colorBorder: calendar.background_color,
                    colorPrimaryBorder: calendar.background_color,
                    colorText: "black",
                    colorPrimary: selectedCalendars.includes(calendar.id)
                      ? calendar.background_color
                      : "white",
                    colorPrimaryHover: calendar.background_color,
                  },
                }}
              >
                <div className="min-w-0 flex-1">
                  <Checkbox
                    checked={selectedCalendars.includes(calendar.id)}
                    onChange={(event) => onCalendarToggle(calendar,event.target.checked)}
                    className="mr-3"
                  >
                    <span className="block w-36 truncate text-xs 2xl:text-sm">
                      {calendar.summary}
                    </span>
                  </Checkbox>
                </div>
              </ConfigProvider>
            </div>
            <Popover
              overlayInnerStyle={{ padding: "0 4px" }}
              content={() => renderPopoverContent(calendar)}
              trigger="click"
              placement="bottomRight"
              arrow={false}
              open={openPopoverId === calendar.id}
              onOpenChange={(visible) =>
                setOpenPopoverId(visible ? calendar.id : null)
              }
            >
              <div>
                <Button
                  type="text"
                  icon={<MoreOutlined />}
                  className={`flex-shrink-0 items-center justify-center w-6 h-6 hover:bg-gray-200 rounded-full ml-2 ${
                    calendar.access_role === "owner" ? "flex" : "hidden"
                  }`}
                  size="small"
                />
              </div>
            </Popover>
          </div>
        </List.Item>
      );
    };

    return (
      <div className="p-2 space-y-4">
        {/* Team Calendars Section */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <span className="">Team calendar</span>
          </div>
          {teamCalendars.length > 0 ? (
            <List
              dataSource={teamCalendars}
              renderItem={renderItem}
              split={false}
              className="max-h-[calc(50vh-200px)] overflow-y-auto scrollbar-hide"
            />
          ) : (
            <div className="text-sm text-gray-600 py-2">
              <Button
                onClick={() => setIsModalOpen(true)}
                className=" text-xs 2xl:text-sm"
              >
                Create team calendar
              </Button>
            </div>
          )}
        </div>

        {/* My Calendars Section */}
        <div>
          <div className="flex justify-between items-center mb-2">
            <span className="">My calendars</span>
          </div>
          <List
            dataSource={personalCalendars}
            renderItem={renderItem}
            split={false}
            className="max-h-[calc(50vh-200px)] overflow-y-auto scrollbar-hide"
          />
        </div>

        <TeamCalendarModal
          isOwner={selectedUserRoleName[0]==OWNER}
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
        />
        {isSettingsModalOpen && (
          <CalendarSharingSettings
            selectedCalendarForEdit={selectedCalendarForEdit}
            isOpen={isSettingsModalOpen}
            onClose={() => setIsSettingsModalOpen(false)}
          />)}
      </div>
    );
  }
);

export default CalendarList;
