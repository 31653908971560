import jwtDecode from "jwt-decode";
import {
  OrganizedBoard,
  currentApplicant,
  wsConn,
  orgStatus,
  currentWsRequest,
  TokenType,
  KeyWithArray,
} from "../../type/type.d";
import {
  candidateDB,
  currentCandidateMails,
  currentCDBactivity,
  currentMailThread,
  current_calendar,
  defaultDomain,
  initUser,
  defaultOpening,
  InitCandidatesInReview,
  initCDBTabelColumn,
  InitCurrentCandidateInEditMode,
  InitCurrentCandidateInReview,
  initialState,
  reviewAttachments,
  reviewComments,
  reviewLabels,
  usermailPreview,
  currentJD,
  currentJDReviewer,
  searchQuery,
  initCompanies,
  initBillingCustomer,
  InitservicesMembership,
  InitcurrentCandidateInPool,
  initCandidatePool,
  InitCurrentTrackerForm,
  WorkspaceBoards,
  careerPageDetails,
  applicantStatus,
  relevancyFilters,
  InitESignDetails,
  JdData,
} from "./initialState";

let openings: any = {};

let initialOpenings: OrganizedBoard = {
  pageSection: {
    sectionName: "",
    openings: openings,
  },
};
let initMembership: any = {};
let initLabels: any = {};
let initOpeningLists: any = {};

let InitCurrentApplicant: currentApplicant = {
  id: "",
  name: "",
  description: "",
  archive: false,
  starred: false,
  created_at: "",
  member_only: false,
  updated_at: "",
  list_id: "",
  opening_id: "",
  socket_client_id: "",
  index: 0,
  creator: "",
  comment_count: 0,
  attachment_count: 0,
  labels: [],
  members: [],
  position: 0,
  rank: "",
  candidate_id: "",
  source: {
    id: "",
    first_name: "",
    last_name: "",
    description: "",
    email: "",
    phone: "",
    country_code: "",
    extension: "",
    source: {
      created_at: "",
      expiry: "",
      from: "",
      id: "",
      jd: "",
      updated_at: "",
      medium:"",
    },
    customs: "",
    attached_jd: "",
    created_at: "",
    updated_at: "",
    labels:[]
  },
};
// let clientId: any;
let archived: any[] = [];
let initComments: any[] = [];
let initActivity: any[] = [];
let applicantTasks: any = {};
let archivedApplicant: any = {};
let applicantLabels: any = {};
let applicantMembers: KeyWithArray = {};
let openingMembers: KeyWithArray = {};
let applicantTask: any = {};
let openingLabels: any = {};
let initLists: any = {};
let attachment: any = [];
let initOpeningActivity: any[] = [];

let attachmentLoader: false = false;
let topicSubscription: any = {};
let archiveList: any = {};
export function login_user(state: any, action: any) {
  const user = {
    ...initUser, // spread the default user object
    ...action.user, // spread the user object passed in the action
    data: {
      ...initUser.data, // spread the default data object
      ...(action.user?.data ?? {}), // spread the data object passed in the action, if it exists
    },
    registrations: action.user?.registrations ?? [], // use the registrations array passed in the action, if it exists
  };
  return {
    ...state,
    loginUser: {
      ...state.loginUser,
      token: action.token,
      user,
    },
  };
}

export function update_user(state: any, action: any) {
  const user = {
    ...initUser, // spread the default user object
    ...action.user, // spread the user object passed in the action
    data: {
      ...initUser.data, // spread the default data object
      ...(action.user?.data ?? {}), // spread the data object passed in the action, if it exists
    },
    registrations: action.user?.registrations ?? [], // use the registrations array passed in the action, if it exists
  };
  return {
    ...state,
    loginUser: {
      ...state.loginUser,
      user,
    },
  };
}

export function logout_user() {
  return initialState;
}

function get_roles_from_token(token: string) {
  // Dispatch the WebSocket action

  const decodedToken: TokenType = jwtDecode(token);
  return decodedToken;
}

export function token_Exchange(state: any, action: any) {
  return {
    ...state,
    loginUser: {
      ...state.loginUser,
      token: action.payload.token,
      services: get_roles_from_token(action.payload.token).services
        ? get_roles_from_token(action.payload.token).services
        : [],
    },
    orgStatus: {
      ...state.orgStatus,
      prev: {
        ...state.orgStatus.prev,
        id:
          state.applicationId != ""
            ? state.applicationId
            : action.payload.applicationID,
      },
      current: {
        ...state.orgStatus.current,
        id: action.payload.applicationID,
      },
    },
    careerPageDetails:careerPageDetails,
    websocketActionRequestQueue: [],
    websocketPrcessedActionRequestQueue: {},
    applicationId: action.payload.applicationID,
    applicationName: get_roles_from_token(action.payload.token).applicationName,
    applicationDesc: action.payload.applicationDesc,
    applicationImgUrl: action.payload.applicationImgURL,
    roles: get_roles_from_token(action.payload.token).roles,
    orgRoles: action.payload.applicationRoles,
    boardFilterKeys: state.boardFilterKeys,
    servicesMembership: state.servicesMembership,
    calendar: current_calendar,
    candidateDB: candidateDB,
    candidateDBTableHeader: initCDBTabelColumn,
    candidatesInReview: InitCandidatesInReview,
    currentCandidateInReview: InitCurrentCandidateInReview,
    currentCandidateInEditMode: InitCurrentCandidateInEditMode,
    candidateLabels: initLabels,
    currentCDBactivity: currentCDBactivity,
    currentCDBAtsActivity: currentCDBactivity,
    reviewAttachments: reviewAttachments,
    reviewComments: reviewComments,
    reviewLabels: reviewLabels,
    userMails: usermailPreview,
    currentMailThread: currentMailThread,
    defaultDomain: defaultDomain,
    currentCandidateMailThread: currentMailThread,
  };
}

export function register_current_org(state: any) {
  return {
    state,
  };
}

export function set_new_org_wsconnection(state: any) {
  return {
    ...state,
    clientId: "",
    wsConnection:
      state.wsConnection.readyState === 1
        ? state.wsConnection.close() === undefined
          ? wsConn
          : state.wsConnection
        : wsConn,
  };
}

export function register(state: any, action: any) {
  return {
    ...state,
    loginUser: {
      ...state.loginUser,
      token: action.payload.token,
      application_id: action.payload.applicationID,
      application_name: action.payload.applicationName,
      user: {
        ...state.loginUser.user,
        registration: action.payload.registration,
      },
    },
  };
}
export function email_Verify(state: any) {
  return {
    ...state,
    loginUser: {
      ...state.loginUser,
      user: {
        ...state.loginUser.user,
        verified: true,
      },
    },
  };
}

export function phone_Verify(state: any) {
  return {
    ...state,
    loginUser: {
      ...state.loginUser,
      user: {
        ...state.loginUser.user,
        data: { verifiedPhone: true },
      },
    },
  };
}

export const org_details_update = (
  state = initialState.careerPageDetails,
  action: {
    payload: {[key: string]: string;}
  }
) => {
  return {
    ...state,
    careerPageDetails: action.payload,
  };
};

export function org_setting_update(state: any, action: any) {
  var newReg: any[] = [];

  state.loginUser.user.registrations.map((app: any, i: number) => {
    if (app.applicationId === state.applicationId) {
      // app.username = (Math.random() + 1).toString(36).substring(7)
      app.data.logo = action.data.logo;
      app.data.description = action.data.description;

      newReg[i] = app;
    } else {
      newReg[i] = app;
    }
  });

  return {
    ...state,
    applicationImgUrl: action.data.logo,
    applicationDesc: action.data.description,

    loginUser: {
      ...state.loginUser,
      reload: !state.loginUser.reload,
      user: {
        ...state.loginUser.user,
        registrations: [...newReg],
      },
    },
  };
}

export function clear_tab_states(state: any) {
  return {
    ...state,
    websocketActionRequestQueue: [],
    websocketPrcessedActionRequestQueue: {},
    billingCustomer: initBillingCustomer,
    wsConnection: wsConn,
    openings: initialOpenings,
    companyBoards: WorkspaceBoards,
    currentOpening: defaultOpening,
    orgStatus: orgStatus,
    clientId: "",
    boardList: initLists,
    members: initMembership,
    labels: initLabels,
    comments: initComments,
    activity: initActivity,
    openingAndLists: initOpeningLists,
    collapseLabel: true,
    archivedItems: archived,
    tasks: applicantTasks,
    attachment: attachment,
    attachmentLoader: attachmentLoader,
    archivedApplicant: archivedApplicant,
    currentApplicant: InitCurrentApplicant,
    applicantLabels: applicantLabels,
    applicantMembers: applicantMembers,
    openingMembers: openingMembers,
    applicantTasks: applicantTask,
    openingLabels: openingLabels,
    topicSubscription: topicSubscription,
    archiveList: archiveList,
    openingActivity: initOpeningActivity,
    currentWsRequest: currentWsRequest,
    boardFilterKeys: state.boardFilterKeys,
    roles: [],
    orgRoles: state.orgRoles,
    candidatesInReview: InitCandidatesInReview,
    currentCandidateInReview: InitCurrentCandidateInReview,
    reviewComments: {},
    currentCandidateInEditMode: InitCurrentCandidateInEditMode,
    reviewAttachments: {},
    reviewLabels: {},
    userMails: usermailPreview,
    currentMailThread: currentMailThread,
    defaultDomain: defaultDomain,
    currentCandidateMails: currentCandidateMails,
    currentCandidateMailThread: currentMailThread,
    currentJD: currentJD,
    currentJDReviewer: currentJDReviewer,
    searchQuery: searchQuery,
    companies: initCompanies,
    servicesMembership: InitservicesMembership,
    currentCandidateInPool: InitcurrentCandidateInPool,
    candidatePool: initCandidatePool,
    currentTrackerJd: InitCurrentTrackerForm,
    currentTrackerForm: InitCurrentTrackerForm,
    applicantStatus: applicantStatus,
    relevancyFilter:relevancyFilters,
    eSignDetails:InitESignDetails,
    jdData:JdData,
  };
}

export function update_user_services(state: any, action: any) {
  return {
    ...state,
    loginUser: {
      ...state.loginUser,
      services: action.data,
    },
  };
}
