// calendarSlice.ts
import { createSlice } from '@reduxjs/toolkit';
import { CalendarColorsResponse, CalendarItem, Calendars } from '../../lib/apiRes';
import type { PayloadAction } from '@reduxjs/toolkit'
import { ProcessedEvent } from '../../components/calender/global/calendarUtils';
// import { CalendarEvent, CalendarState } from './types';

export const initialStateCalendar: CalendarState = {
  colors: {
    calendar: {}, // Provide default value for calendar
    event: {},    // Provide default value for event
    kind: '',     // Provide default value for kind
    updated: ''   // Provide default value for updated
  }, events: [],
  calendarList: {},
  selectedDate: null,
  selectedCalendars: [],
  eventsByCalendar: {},
  boardEventsByCalendar: {},
  connectedClient: "",
  isCalendarConnected:false
};
export interface CalendarEvent {
  id: string;
  title: string;
  start: string;
  end: string;
  description?: string;
}
export interface calendarListState {
  [key: string]: any
}
export interface CalendarEvents {
  [key: string]: ProcessedEvent;
}



export interface CalendarState {
  eventsByCalendar: { [key: string]: CalendarEvents };
  colors: CalendarColorsResponse;
  events: CalendarEvent[];
  selectedDate: string | null;
  selectedCalendars: string[],
  boardEventsByCalendar: { [key: string]: CalendarEvents };
  calendarList: calendarListState,
  connectedClient: string,
  isCalendarConnected:boolean
}


const calendarSlice = createSlice({
  name: 'calendar',
  initialState:initialStateCalendar,
  reducers:
  {
    reset: () => initialStateCalendar,

    addEvent: (state, action: PayloadAction<CalendarEvent>) => {
      state.events.push(action.payload);
    },
    addColors: (state, action: PayloadAction<CalendarColorsResponse>) => {
      state.colors = action.payload;
    },
    addCalendarList: (state, action: PayloadAction<CalendarItem[]>) => {
      const calendarList = action.payload.reduce((acc: { [key: string]: CalendarItem }, item: CalendarItem) => {
        acc[item.id] = item; // Use the id as the key
        return acc;
      }, {}); // Initial value is an empty object
      state.calendarList = calendarList; // Update the calendarList in state
      // Initialize selectedCalendars based on 'selected' property from API

    },
    updateTeamCalendarStatus: (state, action: PayloadAction<{oldTeamCalendarId?: string;newTeamCalendarId: string;}>) => {
      const { oldTeamCalendarId, newTeamCalendarId } = action.payload;
  
      // Create a copy of the calendar list
      const updatedCalendarList = { ...state.calendarList };
    
      // Update old team calendar if it exists
      if (oldTeamCalendarId && updatedCalendarList[oldTeamCalendarId]) {
        updatedCalendarList[oldTeamCalendarId] = {
          ...updatedCalendarList[oldTeamCalendarId],
          is_team_calendar: false
        };
      }
    
      // Update new team calendar
      if (updatedCalendarList[newTeamCalendarId]) {
        updatedCalendarList[newTeamCalendarId] = {
          ...updatedCalendarList[newTeamCalendarId],
          is_team_calendar: true
        };
      }
    
      // Update state with modified calendar list
      state.calendarList = updatedCalendarList;
    },
    setConnectedClient: (state, action: PayloadAction<string>) => {
      state.connectedClient = action.payload;
    },
   
    setSelectedCalendar: (state, action: PayloadAction<string[]>) => {
      const calendarsToToggle = action.payload;
      state.selectedCalendars = [...new Set([...state.selectedCalendars, ...calendarsToToggle])]; // Add new IDs, avoiding duplicates
     
    },
    setInitiallySelectedCalendar: (state, action: PayloadAction<string[]>) => {
      const calendarsToSet = action.payload;
      state.selectedCalendars = [...new Set(calendarsToSet)]; // Set new IDs, avoiding duplicates
     
    },
    removeSelectedCalendars: (state, action: PayloadAction<string[]>) => {
      const calendarsToRemove = action.payload;
      state.selectedCalendars = state.selectedCalendars.filter(id => !calendarsToRemove.includes(id)); // Remove specified IDs
    },
    setCalendarEvents: (
      state,
      action: PayloadAction<{ calendarId: string; events: { [key: string]: ProcessedEvent } }>
    ) => {

      const { calendarId, events } = action.payload;
      state.eventsByCalendar[calendarId] = events;
    },
    removeCalendarEvents: (state, action: PayloadAction<string>) => {
      const calendarId = action.payload;

      delete state.eventsByCalendar[calendarId];
    },
    addCalendarItem: (state, action: PayloadAction<Calendars>) => {
      state.calendarList[action.payload.id] = action.payload;
    },
    deleteEventFromCalendar: (
      state,
      action: PayloadAction<{ calendarId: string; eventId: string }>
    ) => {
      const { calendarId, eventId } = action.payload;
      if (state.eventsByCalendar[calendarId]) {
        delete state.eventsByCalendar[calendarId][eventId];
      }
    },
    editEventInGlobalCalendar: (
      state,
      action: PayloadAction<{
        calendarId: string;
        eventId: string;
        updatedEvent: any
      }>
    ) => {
      const { calendarId, eventId, updatedEvent } = action.payload;

      // Check if the board calendar and event exist
      if (state.eventsByCalendar[calendarId] &&
        state.eventsByCalendar[calendarId][eventId]) {
        // Merge the existing event with updated properties
        state.eventsByCalendar[calendarId][eventId] = {
          ...state.eventsByCalendar[calendarId][eventId],
          ...updatedEvent
        };
      }
    },

    // Board event actions

    setBoardCalendarEvents: (
      state,
      action: PayloadAction<{ calendarId: string; events: { [key: string]: ProcessedEvent } }>
    ) => {

      const { calendarId, events } = action.payload;
      state.boardEventsByCalendar[calendarId] = events;
    },
    // Edit a specific board event
    editEventInBoardCalendar: (
      state,
      action: PayloadAction<{
        calendarId: string;
        eventId: string;
        updatedEvent: Partial<ProcessedEvent>
      }>
    ) => {
      const { calendarId, eventId, updatedEvent } = action.payload;

      // Check if the board calendar and event exist
      if (state.boardEventsByCalendar[calendarId] &&
        state.boardEventsByCalendar[calendarId][eventId]) {
        // Merge the existing event with updated properties
        state.boardEventsByCalendar[calendarId][eventId] = {
          ...state.boardEventsByCalendar[calendarId][eventId],
          ...updatedEvent
        };
      }
    },

    deleteEventFromBoardCalendar: (
      state,
      action: PayloadAction<{ calendarId: string; eventId: string }>
    ) => {
      const { calendarId, eventId } = action.payload;
      if (state.boardEventsByCalendar[calendarId]) {
        delete state.boardEventsByCalendar[calendarId][eventId];
      }
    },
      // Clear all board events (useful for logout or complete reset)
      removeBoardEvents: (state) => {
        state.boardEventsByCalendar = {};
      }
  }




});

export const {
  addEvent, addColors,setInitiallySelectedCalendar, addCalendarList,removeSelectedCalendars,deleteEventFromBoardCalendar, setConnectedClient,setCalendarEvents, setSelectedCalendar, removeCalendarEvents, reset, deleteEventFromCalendar, addCalendarItem, setBoardCalendarEvents, editEventInBoardCalendar, editEventInGlobalCalendar,removeBoardEvents,  updateTeamCalendarStatus // Add this new action
  // Export the new action

} = calendarSlice.actions;

export default calendarSlice.reducer;
