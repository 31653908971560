import React, { useEffect, useState } from "react";
import { getCandidateReviews } from "../../../lib/api";
import {
  CandidateShortReview,
  Opening,
  OrganizedBoard,
  orgMember,
  
} from "../../../type/type";
import { connect } from "react-redux";
import { Avatar, Divider, Empty, Spin } from "antd";
import {
  AlignLeftOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import Label from "../../boardPanel/label/Label";
import { renderCreatedAt } from "../../../utils/renderCreatedAt";
import { generateTooltipForMember } from "../../../utils/capitalizeFirstLetter";
import TacitbaseAvatar from "../../../ant/Avtar";
import AttachmentIcon from "../../Icons/AttachmentIcon";
import { useTracker } from "../../../utils/tabNavigation";

// Define prop types for the component
type CandidateInCDBReviewProps = {
  openings: OrganizedBoard;
  members: orgMember;
  currentCandidate: any;
  currentOpening: Opening;
  applicationId: string;
  currentCandidateInEditMode:any;
  activeTab:string
};

// Component to review candidate details in ATS pool review
const CandidateInCDBReview: React.FC<CandidateInCDBReviewProps> = (props) => {
  const [candidateReviews, setCandidateReviews] = useState<any[]>([]);
  const [showLoading, setShowLoading] = useState<boolean>(false);
  const { handleReview } = useTracker();                 


  // Fetch candidate reviews when the current candidate changes
  useEffect(() => {
    if(props.activeTab!=="Review")return;
    setCandidateReviews([])
    setShowLoading(true)
    getCandidateReviews(props.currentCandidateInEditMode.candidateId.id).then((res) => {
      setShowLoading(false)
      if (res.length > 0) {
        setCandidateReviews(res);
      }
    });
  }, [props.currentCandidateInEditMode.candidateId.id,props.activeTab]);

  const handleClickReview = (id:string) => {
    handleReview(props.applicationId, id);
  };

  return (
    <div className="h-full py-2 pl-2 w-full" key={props.currentCandidateInEditMode.candidateId.id}>
      <div style={{ height: "calc(100vh - 245px)" }} className="overflow-y-scroll w-full ">
        <div className="w-full h-full gap-3.5 flex flex-col px-1">
          {
          candidateReviews.map((review: CandidateShortReview) => (
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => {}}
              style={{
                boxShadow:
                  "var(--ds-shadow-raised,0 1px 1px #091e4240,0 0 1px #091e424f)",
              }}
              className="flex flex-col rounded shadow-lg gap-1 w-full p-3 cursor-pointer hover:shadow-xl hover:bg-gray-100 transition-shadow"
              key={review.id}
              id={review.id}
              onClick={()=>{handleClickReview(review.id)}}
            >
              <span className="text-base font-medium w-full truncate">{review.name}</span>
              <div className="flex flex-wrap">
                {review.labels.length > 0 &&
                  review.labels.map((label, index) => (
                    <Label key={index} color={label.color} flag={true} name={label.name} click={() => {}} />
                  ))}
              </div>
              <div className="flex items-center">
                <div className="flex items-center">
                  {props.members[review.created_by] && props.members[review.created_by].image_url && (
                    <Avatar.Group size={"small"} maxCount={4}>
                      <TacitbaseAvatar
                        src={props.members[review.created_by].image_url}
                        key={props.members[review.created_by].id}
                        size="small"
                        toolTip={`${props.members[review.created_by].first_name} ${props.members[review.created_by].last_name} (Creator)`}
                      />
                    </Avatar.Group>
                  )}
                  <Divider type="vertical" style={{ borderColor: "black" }} />
                  <Avatar.Group maxCount={4} size={"small"}>
                    {review.members.length > 0 &&
                      review.members.map((member: string) =>
                        member !== review.created_by && props.members[member] ? (
                          props.members[member].image_url ? (
                            <TacitbaseAvatar
                              toolTip={generateTooltipForMember(props.members[member])}
                              src={props.members[member].image_url}
                            />
                          ) : (
                            <TacitbaseAvatar
                              toolTip={generateTooltipForMember(props.members[member])}
                              content={`${props.members[member].first_name[0]}${props.members[member].last_name[0]}`}
                            />
                          )
                        ) : null
                      )}
                  </Avatar.Group>
                </div>
              </div>
              <div className="flex items-center gap-4 text-lg justify-between">
                <div className="flex items-center gap-4 text-sm">
                  {review.description && (
                    <div>
                      <AlignLeftOutlined />
                    </div>
                  )}
                  <div>
                    <CommentOutlined /> {review.comment_count}
                  </div>
                  <div>
                    <AttachmentIcon /> {review.attachment_count}
                  </div>
                </div>
                <div className="text-gray-500 text-xs font-normal" style={{ color: "#666666" }}>
                  Created at {renderCreatedAt(review.created_at)}
                </div>
              </div>
            </div>
          ))}
        {candidateReviews.length === 0&& !showLoading &&(
            <Empty
            description="The candidate is not in the review."
            className="flex flex-col h-full justify-center items-center"
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />)}

{candidateReviews.length === 0&& showLoading &&(
  <span className="flex flex-col space-y-2 items-center justify-center h-full">
  <Spin tip="" size="default" />
  <span>Fetching review history</span>
</span>
)}
          
        </div>
      </div>
    </div>
  );
};

// Map Redux state to component props
const mapStateToProps = (state: any) => ({
  currentCandidateInEditMode: state.opening.currentCandidateInEditMode,
  members: state.opening.members,
  currentOpening: state.opening.currentOpening,
  currentCandidate: state.opening.currentCandidateInEditMode,
  openings: state.opening.openings,
  applicationId: state.opening.applicationId,
});

// Connect component to Redux store
export default connect(mapStateToProps)(CandidateInCDBReview);
