import React, { useState } from "react";
import { connect } from "react-redux";
import { updateSearchFlag } from "../../redux/actions/opening/action";
import { Dispatch } from "redux";
import { useHistory } from "react-router-dom";
import {  Tooltip } from "antd";
import { renderCreatedAt } from "../../utils/renderCreatedAt";
import SendToReview from "./trackerCardActionButton/SendToReview";
import TalentPoolIcon from "../Icons/TalentPoolIcon";
import ComposeMail from "./trackerCardActionButton/ComposeMail";
import SendToTracker from "./trackerCardActionButton/SendToTracker";
import { convertUnixToISO } from "../../utils/ConvertUnixToIso";
import { preprocessSnippet } from "./ReviewsCardView";


interface CDBCardViewProps {
  applicationId: string;
  searchResult: any;
  updateSearchFlag(): void;
}

function CDBCardView({ applicationId, searchResult }: CDBCardViewProps) {
  const history = useHistory();
  const [hovered, setHovered] = useState(false); // Hover state

  const showCard = (applicant_id: string) => {
    history.push({
      pathname: `/o/${applicationId}/db/${applicant_id}`,
    });
  };

  const { document } = searchResult;

  // Fallback logic for name and description
  const first_nameSnippet = document.highlight.first_name
    ? document.highlight.first_name.snippet
    : document.first_name;
  const last_nameSnippet = document.highlight.last_name
    ? document.highlight.last_name.snippet
    : document.last_name;
  const nameSnippet = first_nameSnippet + " " + last_nameSnippet;
  const descriptionSnippet = document.highlight.description
    ? document.highlight.description.snippet
    : document.description;
  const date = convertUnixToISO(document.updated_at);
  const sanitizedDescription = descriptionSnippet.replace(/<br\s*\/?>/g, " ");

  return (
    <div
      className="flex flex-row rounded items-start  p-2 gap-2 "
      style={{ width: "100%" }}
      onMouseEnter={() => setHovered(true)} // Set hovered state to true on mouse enter
      onMouseLeave={() => setHovered(false)} // Set hovered state to false on mouse leave
    >
      <Tooltip title="Talent pool">
      {/* <Avatar
        style={{ backgroundColor: "#4B48A5" }}
        icon={<TalentPoolIcon />}
      /> */}
      <TalentPoolIcon style={{fontSize:'20px'}} />
      </Tooltip>
      <div className="flex flex-row justify-between" style={{ width: "95%" }}>
        <div
          className="flex flex-col space-y-0.5 "
          onClick={() => showCard(document.id)}
          role="button"
          tabIndex={0}
          style={{ width: hovered ? "80%" : "100%" }}
          // style={{ width: hovered?"85%":"100%"}}
          onKeyDown={() => showCard(document.id)}
          data-testid={document.id}
        >
          <div className="flex flex-row justify-between  text-xs font-medium w-full">
            <div
              className="flex leading-4 truncate break-words text-xs tracking-normal font-medium rounded pl-1 overflow-ellipsis"
              style={{ maxWidth: "70%" }}
              dangerouslySetInnerHTML={{ __html: preprocessSnippet(nameSnippet)  }} // Render highlighted name or fallback
            />

         {!hovered && renderCreatedAt(date).toString()}
          </div>
          <div className="flex justify-between text-xs font-medium w-full">
            <div
              className="text-xs text-gray-800 pl-1 truncate"
              style={{
                display: "inline", // Use flex to ensure all content is on the same line
                alignItems: "center", // Align vertically in the center
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                maxWidth: hovered ? "70%" : "55%",
                overflowWrap: "break-word", // Break long words if necessary
              }}
            >
              {/* <span>Description: </span> */}
              {descriptionSnippet ? (
                <span
                  dangerouslySetInnerHTML={{
                    __html: sanitizedDescription.replace(/<\/?p>/g, "").replace(/<\/?h[1-6]>/g, ""),
                  }}
                  // style={{ marginLeft: "4px" }} // Add some spacing between "Description:" and the content
                />
              ) : (
                <span style={{ color: "gray" }}>
                  Description is not available
                </span>
              )}
            </div>
          </div>
        </div>
        {hovered && (
          <div
            className="flex flex-row justify-end space-x-1 items-center pr-2"
            onClick={(e) => e.stopPropagation()}
            role="button"
            tabIndex={0}
            onKeyPress={(e) => e.stopPropagation()}
          >
            <SendToTracker candidate={searchResult.document} />
            <SendToReview candidate={searchResult.document} />
            <ComposeMail currentApplicant={searchResult.document} />
          </div>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state: any) => ({
  applicationId: state.opening.applicationId,
});

const mapPropsToState = (dispatch: Dispatch) => ({
  updateSearchFlag: () => dispatch(updateSearchFlag()),
});

export default connect(mapStateToProps, mapPropsToState)(CDBCardView);
