import React from "react"
import { connect, useDispatch } from "react-redux"
import { RouteComponentProps, withRouter } from "react-router"
import { Space } from "antd"

import {
    LogoutOutlined,
} from "@ant-design/icons"
import { LabeledIconButton } from "../../ant/Button"
import { logoutFromAllTabs } from "../../utils/logoutFromAllTabs"
import { resetApp } from "../../redux"

type Props = {
    wsConnectionObject: WebSocket,
} & RouteComponentProps

const Logout: React.FC<Props> = ({  wsConnectionObject }) => {
    const dispatch=useDispatch()
    const logout = () => {
        localStorage.setItem('logout', String(Date.now()));
        logoutFromAllTabs()
        dispatch(resetApp())
        let ws = wsConnectionObject
        if (ws && ws.readyState !== WebSocket.CLOSED) {
          ws?.close()
        }
      
      }
    return (
        <>
            <Space>
                <LabeledIconButton label="Sign out" type="primary" icon={<LogoutOutlined   />} onClick={logout} />
            </Space>
        </>
    )
}

const mapStateToProps = (state: any) => ({
    wsConnectionObject: state.opening.wsConnection,
})

export default connect(mapStateToProps)(withRouter(Logout))
