import React from "react";
import { Tag } from "antd";
import { 
  CheckCircleOutlined, 
  CheckOutlined, 
  EyeInvisibleOutlined, 
  EyeOutlined, 
  FileProtectOutlined, 
  FileTextOutlined, 
  MailOutlined, 
  MinusCircleOutlined, 
  SendOutlined,
  StopOutlined
} from "@ant-design/icons";

type GetDocumentStatusProps = {
  documentStatus: string;
};

export const GetDocumentStatus: React.FC<GetDocumentStatusProps> = ({ documentStatus }) => {
  const getColor = (status: string): string => {
    switch (status.toLowerCase()) {
      case "canceled":
      case "voided":
      case "recalled":
        return "red";
      case "incomplete":
      case "inprogress":
        return "blue";
      case "complete":
      case "completed":
        return "green";
      case "sent":
        return "geekblue";
      case "delivered":
        return "purple";
      default:
        return "default";
    }
  };

  return (
    <div>
      <Tag color={getColor(documentStatus)}>
        {documentStatus.charAt(0).toUpperCase() + documentStatus.slice(1)}
      </Tag>
    </div>
  );
};

type RecipientStatusProps = {
  status: string;
};

export const RecipientStatus: React.FC<RecipientStatusProps> = ({ status }) => {
  const getStatusConfig = (status: string) => {
    switch (status.toLowerCase()) {
      case "not_viewed":
        return {
          icon: <EyeInvisibleOutlined />,
          color: "default",
          text: "Not Viewed",
        };
      case "viewed":
        return {
          icon: <EyeOutlined />,
          color: "processing",
          text: "Viewed",
        };
      case "finalized":
        return {
          icon: <CheckCircleOutlined />,
          color: "success", 
          text: "Finalized",
        };
      case "declined":
        return {
          icon: <StopOutlined />,
          color: "error",
          text: "Declined",
        };
      case "sent":
        return {
          icon: <SendOutlined />,
          color: "processing",
          text: "Sent",
        };
      case "delivered":
        return {
          icon: <CheckOutlined />,
          color: "success",
          text: "Delivered",
        };
      case "completed":
        return {
          icon: <CheckCircleOutlined />,
          color: "success",
          text: "Completed",
         };
      case "unopened":
        return {
          icon: <MailOutlined />,
          color: "default",
          text: "Unopened",
          };
      case "noaction":
        return {
          icon: <MinusCircleOutlined />,
          color: "warning",
          text: "No Action",
        };
     case "signed":
       return {
         icon: <FileProtectOutlined />,
         color: "success", 
         text: "Signed",
       }
      default:
        return {
          icon: <FileTextOutlined />,
          color: "default",
          text: status.charAt(0).toUpperCase() + status.slice(1),
        };
    }
  };

  const config = getStatusConfig(status);

  return (
    <Tag
      icon={config.icon}
      color={config.color}
    >
      {config.text}
    </Tag>
  );
};
