import React, { useRef, useState } from "react";
import { usePopper } from "react-popper";
import { connect } from "react-redux"
import { boardVisibilityActions, MEMBER_ONLY, PUBLIC } from "../../../constant";
import Read from "../../../Read";
import { loginUser, Opening, Scope } from "../../../type/type.d";
import { capitalizeFirstLetter } from "../../../utils/capitalizeFirstLetter";
import { Overlay } from "../../hook/Overlay";
import Portal from "../../portal/Portal";
import ShowBoardVisibilityPopup from "./ShowBoardVisibilityPopup";
import { Typography } from "antd"
const { Text } = Typography

type ShowBoardStatusProps = {
  currentOpening: Opening;
  loginUser: loginUser;
};
const ShowBoardStatus = (props: ShowBoardStatusProps) => {
  let refer = useRef(null);
  const [open, setOpen] = useState<boolean>(false);
  let [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>();
  let [popperElement, setPoperElement] = useState<HTMLDivElement | null>();
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-start",
  });
  return (
    <div>
      <div
        ref={setReferenceElement}
        // onClick={() => setOpen(!open)}
        // role="button"
        // tabIndex={0} 
        // onKeyDown={() => {}}
        style={{height:30}}
        className={`${
          props.currentOpening.access.admin
            ? "cursor-pointer"
            : "cursor-default"
          } isEditable  flex justify-start items-center space-x-1  text-sm  text-white rounded-default `}
      >
        {/* {props.currentOpening.member_only ? (
          <MembersSmallIcon size={"14px"} fill={"#fff"} />
        ) : null} */}
        <Text className="text-white">
          {props.currentOpening.member_only
            ? capitalizeFirstLetter(MEMBER_ONLY)
            : capitalizeFirstLetter(PUBLIC)}
        </Text>{" "}
        {/* <Read access={props.currentOpening.access} type={true}>
          <div className="pt-1.5 pb-1 px-2  rounded hover:rounded hover:bg-black hover:bg-opacity-30">
            <DownOutlined />
          </div>

        </Read> */}
      </div>
      {open ? (
        <Read access={props.currentOpening.access} type={true}>

        <Overlay onClose={() => setOpen(!open)} scope={Scope.Popover}>
          <Portal>
            <div
              ref={setPoperElement}
              style={styles.popper}
              {...attributes.popper}
            >
              <ShowBoardVisibilityPopup
                refer={refer}
                close={() => setOpen(!open)}
                visibilityActions={boardVisibilityActions}
              />
            </div>
          </Portal>
          </Overlay>
        </Read>
      ) : null}
    </div>
  );
};
const mapStateToProps = (state: any) => ({
  currentOpening: state.opening.currentOpening,
  loginUser: state.opening.loginUser,
});
export default connect(mapStateToProps)(ShowBoardStatus);
