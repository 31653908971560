import {  Button, Divider, Select, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {  SOCKET_CLIENT_ID } from "../../../../constant";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { move } from "../../../../redux/actions/opening/action";
import { currentApplicant, JobBoard, Opening, OrganizedBoard } from "../../../../type/type.d";
import { useHistory } from "react-router-dom";
import { getListByListIDs, getOpenings, moveApplicant } from "../../../../lib/api";
import SelectPosition from "../../../cardActions/copyCard/SelectPosition";
import { throwSuccess } from "../../../../service/throwError";

const { Text } = Typography;

type MoveCardProps = {
  click:()=>void;
  openings: OrganizedBoard;
  currentApplicant: currentApplicant;
  currentOpening: Opening;
  move: (applicant: any, currentListId: any, selectedApplicantPosition: any) => void;
  boardList: any;
  clientId: any;
  applicationId: string;
};

const MoveCard = ({
  currentApplicant,
  currentOpening,
  boardList,
  move,
  applicationId,
  click
}: MoveCardProps) => {
  const history = useHistory();

  const [lists, setLists] = useState<any[]>(Object.values(boardList));
  const [openings, setOpenings] = useState<Opening[]>([]);
  const [companyOptions, setCompanyOptions] = useState<Record<string, JobBoard>>({});
  const [selectedCompany, setSelectedCompany] = useState(currentOpening.company_id);
  const [selectedApplicant, setSelectedApplicant] = useState<string | number>(
    getCurrentApplicantIndex(currentApplicant, boardList)
  );
  const [listID, setListID] = useState<string>("");
  const [openingID, setOpeningID] = useState(currentOpening.id);
  const [applicants, setApplicants] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);

  function getCurrentApplicantIndex(currentApplicant: currentApplicant, boardList: any) {
    return boardList[currentApplicant.list_id]?.applicants
      ?.map((item: any) => item.id)
      .indexOf(currentApplicant.id) || 0;
  }
  useEffect(() => {
    fetchOpenings().then(() => {
      if (currentOpening.id) {
        fetchListByOpeningID(currentOpening.id);
      }
    });
  }, []);

  useEffect(() => {
    updateApplicantsByList(listID);
  }, [listID, lists]);

  useEffect(() => {
    if (selectedCompany) {
      updateBoardsByCompany(selectedCompany);
    }
  }, [selectedCompany, companyOptions]);

  const fetchOpenings = async () => {
    getOpenings()
    .then((openings: any) => {
      openings = openings ? openings : [];
      const companyOptionsObject: Record<string, JobBoard> = {}
      openings.forEach((company: JobBoard) => {
        companyOptionsObject[company.company_id] = company
      })
      setCompanyOptions(companyOptionsObject)
    })
  };

  const updateApplicantsByList = (listID: string) => {
    const index = lists.findIndex((list) => list.id === listID);
    const newApplicants = lists[index]?.applicants || [];
    setApplicants(newApplicants);
    setSelectedApplicant(0);
  };

  const updateBoardsByCompany = (companyId: string) => {
    const selectedCompanyData = companyOptions[companyId];
    if (selectedCompanyData) setOpenings(selectedCompanyData.boards || []);
  };

  const fetchListByOpeningID = async (openingId: string) => {
    const listsWithApplicants = await getListByListIDs(openingId);
    if (listsWithApplicants) {
      setLists(listsWithApplicants);
      if (currentOpening.id !== openingId) {
        setListID(listsWithApplicants[0].id);
        setApplicants(listsWithApplicants[0].applicants);
      }
    }
  };

  const handleWorkSpaceChange = (value: string) => {
    setSelectedCompany(value);
    setOpeningID("");
    setListID("");
  };
  const handleMove = async () => {
    if (lists) {
        const applicantIndex = typeof selectedApplicant === 'number' ? selectedApplicant : 0;
      const previousApplicantId = applicantIndex > 0 ? applicants[applicantIndex - 1]?.id : "00000000-0000-0000-0000-000000000000";
      const req = {
        src_applicant: {
          id: currentApplicant.id,
          opening_id: currentOpening.id,
          list_id: currentApplicant.list_id,
        },
        dst_applicant: {
          id: previousApplicantId,
          opening_id: openingID,
          list_id: listID,
        },
        socket_client_id: sessionStorage.getItem(SOCKET_CLIENT_ID),
        position: selectedApplicant.toString(),
      };
      setLoading(true)
      try {
        const response = await moveApplicant(req);
        move(response, currentApplicant.list_id, selectedApplicant);
        throwSuccess("Applicant moved successfully");
        setLoading(false);
        click()
        setOpeningID("");
        setListID("");
        setSelectedCompany("")
        if (currentOpening.id !== openingID) {
          history.push(`/o/${applicationId}/b/${currentOpening.id}/tracker`);
        }
      } catch (error) {
        setLoading(false);
      }
    }
  };

  const handleBoardChange = (value: string) => {
    fetchListByOpeningID(value);
    setOpeningID(value);
  };
  return (
    <>
      <Divider type="horizontal" className="my-2" />
      
      <div
        className="flex w-72 flex-col"
        style={{ minHeight: "auto", maxHeight: "330px" }}
      >
        <div className="flex-1 overflow-y-auto">

        <div className="my-2">
          <Text>Select destination</Text>
        </div>
        <div className="w-full flex flex-col gap-1 mb-2">
          <Text strong>Workspace</Text>
          <Select
           showSearch
            className="w-full"
            value={selectedCompany}
            onChange={(value) => handleWorkSpaceChange(value)}
            data-testid="selectBoard"
            filterOption={(input, option:any) =>
              option?.children.toLowerCase().includes(input.toLowerCase())
          }
          >
            {Object.keys(companyOptions).map((companyId) => (
              <Select.Option key={companyId} value={companyId}>
                {companyOptions[companyId].company_name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="w-full mb-2 flex flex-col gap-1">
          <Text strong>Job post</Text>
          <Select
          showSearch
            className="w-full"
            value={openingID}
            onChange={handleBoardChange}
            data-testid="selectTestBtn"
            filterOption={(input, option:any) =>
              option?.children.toLowerCase().includes(input.toLowerCase())
          }
          >
            {openings.map((opening) => (
              <Select.Option key={opening.id} value={opening.id}>
                {opening.name}
              </Select.Option>
            ))}
          </Select>
        </div>
        <div className="mb-2 w-full">
          <div className="w-full flex flex-col gap-1">
            <Text strong>Lists</Text>
            <Select
            showSearch
              className="w-full"
              onChange={(value) => setListID(value)}
              data-testid="listTestBtn"
              filterOption={(input, option:any) =>
                option?.children.toLowerCase().includes(input.toLowerCase())
            }
            >
              {lists.map((list) => (
                <Select.Option key={list.id} value={list.id}>
                  {list.name}
                </Select.Option>
              ))}
            </Select>
          </div>
        </div>
        <div className="mb-2">
          {(applicants.length > 0 && listID) && (
            <SelectPosition
              label="Position"
              value={applicants}
              event={(value) => setSelectedApplicant(value)}
              comparedId={currentApplicant.id}
              move={true}
              extraIndexCheck={currentApplicant.list_id}
              sourceId={listID}
            />
          )}
          {applicants.length < 0 && listID && <div>No applicants</div>}
        </div>
        </div>
        <Button
          type="primary"
          className="w-24"
          loading={loading}
          onClick={handleMove}
          disabled={!lists}
        >
          Move
        </Button>
        </div>
      
    </>
  );
};

const mapStateToProps = (state: any) => ({
  openings: state.opening.openings,
  currentOpening: state.opening.currentOpening,
  currentApplicant: state.opening.currentApplicant,
  boardList: state.opening.boardList,
  clientId: state.opening.clientId,
  applicationId: state.opening.applicationId,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  move: (applicant: any, currentListId: any, selectedApplicantPosition: any) =>
    dispatch(move(applicant, currentListId, selectedApplicantPosition)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MoveCard);
