import React, { useEffect, useState } from "react";
import { Button, Select, Modal, Space, Typography, Spin } from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import {
  addCalendarMember,
  getCalendarMembers,
  removeCalendarMember,
  updateCalendarMemberRole,
} from "../../../lib/api";
import { CalendarItem, calendarMemberItem, Member } from "../../../lib/apiRes";
import { useSelector } from "react-redux";
import TacitbaseAvatar from "../../../ant/Avtar";
import { throwSuccess } from "../../../service/throwError";
import { loginUser } from "../../../type/type";
import {
  permissionOptionsGoogle,
  permissionOptionsOutlook,
} from "../../../constant";
import Tooltip from "../../../ant/Tooltip";
import CloseIconWithTooltip from "../../../ant/CloseIconWithTooltip";
import getOrgMembers from "../../../utils/getOrgMember";
const { Text } = Typography;

interface CalendarSharingProps {
  isModal?: boolean;
  isOpen?: boolean;
  onClose?: () => void;
  selectedCalendarForEdit?: CalendarItem;
  className?: string;
}

const CalendarSharing: React.FC<CalendarSharingProps> = ({
  isModal = true,
  isOpen = false,
  onClose = () => {},
  selectedCalendarForEdit,
  className = "",
}) => {
  const [sharedUsers, setSharedUsers] = useState<calendarMemberItem[]>([]);
  const [showAddInput, setShowAddInput] = useState(false);
  const [selectedMember, setSelectedMember] = useState<string>("");
  const [newPermission, setNewPermission] = useState("reader");
  const [addingMemberLoading, setAddingMemberLoading] = useState(false);
  const orgMembers: any = getOrgMembers();
  const loginUserData: loginUser = useSelector(
    (state: any) => state.opening.loginUser
  );
  const connectedClient: "outlook" | "google" = useSelector(
    (state: any) => state.calendar.connectedClient
  );
  const [fetchingCalendarMembers, setIsFetchingCalendarMembers] =
    useState(false);

  const handleAddUser = () => {
    setShowAddInput(true);
  };

  const handleRemoveUser = (id: string) => {
    if (selectedCalendarForEdit)
      removeCalendarMember(
        selectedCalendarForEdit.id,
        loginUserData.user.id,
        id
      ).then(() => {
        setSharedUsers(
          sharedUsers.filter((user: calendarMemberItem) => user.id !== id)
        );
      });
  };

  const updateMemberPermission = (id: string, value: string) => {
    const previousUsers = [...sharedUsers];

    setSharedUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === id ? { ...user, role: value } : user
      )
    );
    if (selectedCalendarForEdit)
      updateCalendarMemberRole(
        selectedCalendarForEdit.id,
        loginUserData.user.id,
        id,
        { role: value }
      )
        .then((res) => {
          setSharedUsers((prevUsers) =>
            prevUsers.map((user) =>
              user.id === id ? { ...user, role: res.role } : user
            )
          );
          throwSuccess("Permission changed successfully.");
        })
        .catch((error) => {
          console.log(error);
          setSharedUsers(previousUsers);
        });
  };

  useEffect(() => {
    const fetchCalendarMembers = async () => {
      if (selectedCalendarForEdit?.id) {
        try {
          setIsFetchingCalendarMembers(true);
          const response = await getCalendarMembers(
            selectedCalendarForEdit.id,
            loginUserData.user.id
          );
          setSharedUsers(response.items);
        } catch (error) {
          console.error("Error fetching calendar members:", error);
        } finally {
          setIsFetchingCalendarMembers(false);
        }
      }
    };

    fetchCalendarMembers();
  }, [selectedCalendarForEdit?.id, loginUserData.user.id]);

  const handleSubmitNewUser = () => {
    if (selectedMember !== null) {
      if (selectedCalendarForEdit?.id)
        setAddingMemberLoading(true),
          addCalendarMember(
            selectedCalendarForEdit?.id,
            loginUserData.user.id,
            {
              role: newPermission,
              scope: {
                type: "user",
                value: selectedMember,
              },
            }
          )
            .then((res) => {
              setNewPermission("reader");
              setSelectedMember("");
              setSharedUsers([...sharedUsers, res]);
              setShowAddInput(true);
            })
            .catch((error) => {
              console.error(error);
            })
            .finally(() => {
              setAddingMemberLoading(false);
            });
    }
  };

  const handleClose = () => {
    setShowAddInput(false);
    setSharedUsers([]);
    onClose();
  };

  // Filter out already shared members from org members
  const availableOrgMembers = orgMembers.filter(
    (member: Member) =>
      !sharedUsers.some((sharedUser) => sharedUser.scope.value === member.email)
  );

  const renderContent = () => (
    <div className={`space-y-6 my-6 overflow-y-scroll -mr-6 pr-5 ${className}`} style={{maxHeight:'550px'}}>
      {fetchingCalendarMembers ? (
        <div className="flex flex-col items-center justify-center py-8 space-y-4">
          <Spin /> <p className="text-gray-500">Fetching shared members...</p>
        </div>
      ) : sharedUsers.length === 0 && !showAddInput ? (
        <div className="flex flex-col items-center justify-center py-8 space-y-2">
          <p className="text-gray-500">No members shared yet</p>
          <p className="text-sm text-gray-400">
            Add people to share this calendar with them
          </p>
        </div>
      ) : (
        <>
          {sharedUsers.map((user) => (
            <div
              key={user.id}
              className="flex items-center justify-between border-b w-full space-x-2"
            >
              <Space align="center" size="middle" className="w-auto">
                <TacitbaseAvatar
                  content={`${user.scope.value[0]}`}
                  key={user.id}
                  size="small"
                  toolTip={`${user.scope.value}`}
                />
                <Space.Compact direction="vertical" className="w-full">
                  <Text
                    className=" line-clamp-1"
                    style={{ margin: 0 }}
                  >
                    {user.scope.value}
                  </Text>
                  <Text type="secondary">{user.role}</Text>
                </Space.Compact>
              </Space>
              <div className="flex items-center space-x-3">
                <Select
                  value={user.role}
                  disabled={user.role == "owner"}
                  className="w-[280px]"
                  options={
                    connectedClient == "google"
                      ? permissionOptionsGoogle
                      : connectedClient == "outlook"
                      ? permissionOptionsOutlook
                      : []
                  }
                  onChange={(value) => updateMemberPermission(user.id, value)}
                />
                <Tooltip
                  arrow={false}
                  content={`Unshare calendar with ${user.scope.value} `}
                >
                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={() => handleRemoveUser(user.id)}
                    className="text-gray-400 hover:text-gray-200"
                  />
                </Tooltip>
              </div>
            </div>
          ))}
        </>
      )}

      {/* Add New User Select Row */}
      {showAddInput && (
        <div className="flex items-center justify-between w-full space-x-2">
          <div className="flex items-center space-x-3" style={{ width: 260 }}>
            <TacitbaseAvatar content={``} key={0} size="small" toolTip={``} />
            <Select showSearch
              allowClear
              onClear={() => setSelectedMember("")}
              placeholder="Select member"
              value={
                selectedMember
                  ? { value: selectedMember, label: selectedMember }
                  : undefined
              }
              className="flex-1"
              onChange={(selected) =>
                selected ? setSelectedMember(selected?.value) : ""
              }
              labelInValue
              options={availableOrgMembers.map((member: Member) => ({
                value: member.email,
                label: (
                  <div className="flex items-center space-x-3">
                    <div className="w-max">
                      {member.image_url ? (
                        <TacitbaseAvatar
                          src={member.image_url}
                          key={member.id}
                          size="small"
                          toolTip={`${member.first_name} ${member.last_name}`}
                        />
                      ) : (
                        <TacitbaseAvatar
                          content={`${member.first_name[0]}${member.last_name[0]}`}
                          key={member.id}
                          size="small"
                          toolTip={`${member.first_name} ${member.last_name}`}
                        />
                      )}
                    </div>
                    <div className="truncate text-left flex justify-start flex-col">
                      <div className="font-medium">
                        {member?.first_name} {member?.last_name}
                      </div>
                      <div className="text-xs text-gray-400">
                        {member.email}
                      </div>
                    </div>
                  </div>
                ),
              }))}
            />
          </div>
          <div className="flex items-center space-x-3">
            <Select
              value={newPermission}
              onChange={(value) => setNewPermission(value)}
              className="w-[280px]"
              options={
                connectedClient == "google"
                  ? permissionOptionsGoogle
                  : connectedClient == "outlook"
                  ? permissionOptionsOutlook
                  : []
              }
            />
            <Button
              type="primary"
              size="small"
              loading={addingMemberLoading}
              onClick={handleSubmitNewUser}
            >
              Add
            </Button>
            <Tooltip content={"Cancel adding member"}>
              <Button
                type="text"
                icon={<CloseOutlined />}
                onClick={() => setShowAddInput(false)}
                className="text-gray-400 hover:text-gray-200"
              />
            </Tooltip>
          </div>
        </div>
      )}

      {/* Add People Button */}
      {!showAddInput && !fetchingCalendarMembers && (
        <div className="flex items-center">
          <Button
            type="default"
            icon={<PlusOutlined />}
            onClick={handleAddUser}
          >
            <span>Add people</span>
          </Button>
        </div>
      )}
      <p className="">
        <Text type="secondary">
          Your organization might limit how you can share your calendar outside
          your organization.
        </Text>
      </p>
    </div>
  );

  // Render as Modal or Standalone Component
  if (isModal) {
    return (
      <Modal
        title="Sharing and permissions"
        open={isOpen}
        onCancel={handleClose}
        footer={null}
        width={700}
        closeIcon={<CloseIconWithTooltip />}
      >
        {renderContent()}
      </Modal>
    );
  }

  return (
    <>
      <div>
        <div className="flex flex-col pt-4 pb-6">
          <Text >
            To start using <span className=" font-semibold">{selectedCalendarForEdit?.summary}</span> calendar, add the
            people you want to share it with.
          </Text>
        </div>
        {renderContent()}
      </div>
    </>
  );
};

export default CalendarSharing;
